import types from '@/store/app/masterData/utils/types';

const {
  SAVE,
} = types;

export default {
  /**
   * @description Save master data
   * @param {Object} state - Current master data
   * @param {Object} payload - New master data
   */
  [SAVE](state, payload) {
    state.data = { ...state.data, ...payload };
  },
};
