import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { configuration, cumulioAuth } = endpoints.subscriptions;

/**
* @description Get Subscriptions configuration
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#subscriptions_configuration_get}
*/
async function getConfiguration({ token, userInfo }) {
  try {
    const response = await axios.get(configuration, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
* @description Get cumul.io auth key and token
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
*/
async function getCumulioAuth({ token, userInfo }, payload) {
  try {
    const endpoint = `${cumulioAuth}/${payload.integrationId}`;
    const response = await axios.post(endpoint, payload, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getConfiguration,
  getCumulioAuth,
};
