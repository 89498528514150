import routesNames from '@/router/routes-names';
import store from '@/store';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;
const { subscriptions } = routesNames;

const isEnabled = () => (store.getters[`${APP.FEATURES}/isPromosEnabled`]);
const promosRoles = store.getters[`${APP.FEATURES}/getPromosRoles`];

const PromosList = () => import('@/views/subscriptions/promos/promos-list.vue');
const CreatePromo = () => import('@/views/subscriptions/promos/create-promo.vue');
const ViewPromo = () => import('@/views/subscriptions/promos/view-promo.vue');
const EditPromo = () => import('@/components/subscriptions/promos/edit-promo.vue');
/**
 * @description Get subscriptions user management route
 * @returns {Array<Object>}
 */
export default [{
  component: PromosList,
  path: `/${subscriptions.main}/${subscriptions.promos.main}`,
  name: `${subscriptions.main}.${subscriptions.promos.main}`,
  meta: {
    authorize: promosRoles,
    isEnabled,
    breadcrumbs: [
      {
        i18n: 'app.breadcrumbs.subscriptions',
        disabled: true,
      },
      {
        i18n: 'app.breadcrumbs.promos',
      },
    ],
  },
  children: [
    {
      component: EditPromo,
      path: `${subscriptions.promos.edit}/:promoId`,
      name: `${subscriptions.main}.${subscriptions.promos.main}.${subscriptions.promos.edit}`,
      props: true,
      meta: {
        authorize: promosRoles,
        isEnabled,
        breadcrumbs: [
          {
            i18n: 'app.breadcrumbs.subscriptions',
            disabled: true,
          },
          {
            i18n: 'app.breadcrumbs.promos',
          },
        ],
      },
    },
  ],
}, {
  component: ViewPromo,
  path: `/${subscriptions.main}/${subscriptions.promos.main}/${subscriptions.promos.view}/:promoId`,
  name: `${subscriptions.main}.${subscriptions.promos.main}.${subscriptions.promos.view}`,
  meta: {
    authorize: promosRoles,
    isEnabled,
    breadcrumbs: [
      {
        i18n: 'app.breadcrumbs.subscriptions',
        disabled: true,
      },
      {
        i18n: 'app.breadcrumbs.promos',
        to: { name: `${subscriptions.main}.${subscriptions.promos.main}` },
      },
      {
        i18n: 'app.breadcrumbs.viewCampaign',
      },
    ],
  },
}, {
  component: CreatePromo,
  path: `/${subscriptions.main}/${subscriptions.promos.main}/${subscriptions.promos.create}`,
  name: `${subscriptions.main}.${subscriptions.promos.main}.${subscriptions.promos.create}`,
  meta: {
    authorize: promosRoles,
    isEnabled,
    breadcrumbs: [
      {
        i18n: 'app.breadcrumbs.subscriptions',
        disabled: true,
      },
      {
        i18n: 'app.breadcrumbs.promos',
        to: { name: `${subscriptions.main}.${subscriptions.promos.main}` },
      },
      {
        i18n: 'app.breadcrumbs.createCampaign',
      },
    ],
  },
}];
