import routesNames from '@/router/routes-names';

const { exchange } = routesNames;

const Dashboard = () => import(/* webpackChunkName: "async-xc-dashboard" */ '@/views/exchange/dashboard/dashboard.vue');

/**
 * @description Get exchange dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: Dashboard,
  path: `/${exchange.main}/${exchange.dashboard.main}`,
  name: `${exchange.main}.${exchange.dashboard.main}`,
}];
