import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapReportsParametersToQuery from '@/middleware/api/utils/mapReportsParemetersToQuery';

const { reports } = endpoints;

/**
 * Get master data
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#reports_get }
 */
async function getReports({ token, userInfo, params }) {
  try {
    const response = await axios.get(reports.main, {
      ...axiosConfiguration(token),
      params,
    });
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get single report file
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @param {Object} params - Params to pass to the endpoint
 * @returns {Promise}
 */

async function getSingleFile({ token, userInfo, params }) {
  try {
    const response = await axios({
      url: `${reports.main + reports.download}`,
      method: 'GET',
      responseType: 'blob',
      params: mapReportsParametersToQuery(params),
      baseURL: `${process.env.VUE_APP_API}/api`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  reports: {
    getReports,
    getSingleFile,
  },
};
