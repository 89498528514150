import routesNames from '@/router/routes-names';
import { getPath, getRedirect, getChildren } from '@/router/routes/upsells/utils/getProgramRoutes';

const { program } = routesNames.upsells;

const Edit = () => import(/* webpackChunkName: "async-up-program-edit" */ '@/views/upsells/program/edit/edit.vue');

const Create = () => import(/* webpackChunkName: "async-up-program-create" */ '@/views/upsells/program/create/create.vue');

/**
 * @description Get upsells program routes
 * @returns {Array<Object>}
 */
export default [
  {
    component: Edit,
    path: getPath(program.edit),
    children: getChildren(program.edit),
    redirect: getRedirect(program.edit),
  },
  {
    component: Create,
    path: getPath(program.create),
    children: getChildren(program.create),
    redirect: getRedirect(program.create),
  },
];
