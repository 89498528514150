import routesNames from '@/router/routes-names';

const { profile, logout } = routesNames;

/**
 * Get the user data
 *
 * @this ContextualNavigation
 */
export default function userData() {
  return {
    title: this.$t('app.sideNavbar.profile.title'),
    sections: [
      {
        icon: this.$t('app.icons.list'),
        link: `/${profile.main}`,
        title: this.$t('app.sideNavbar.account.title'),
      },
      {
        icon: this.$t('app.icons.power'),
        link: `/${logout}`,
        title: this.$t('app.sideNavbar.logout.title'),
      },
    ],
  };
}
