/**
 * Remove old icons
 */
function removeOldIcons() {
  const links = document.querySelectorAll("link[rel*='icon']");

  if (links) {
    for (let i = 0; i < links.length; i += 1) {
      document.head.removeChild(links[i]);
    }
  }
}

/**
 * Create the icon link
 *
 * @param {string} tenant - The current tenant
 * @param {string} sizes - The link sizes
 * @returns {Element} - The icon link element
 */
function createIconLink(tenant, sizes) {
  const link = document.createElement('link');

  link.rel = 'icon';
  link.type = 'image/png';
  link.sizes = sizes;
  link.href = `favicons/${tenant}/favicon-${sizes}.png`;

  return link;
}

/**
  * Add new icons
  *
  * @param {string} tenant - The current tenant
  */
function addNewIcons(tenant) {
  const link16x16 = createIconLink(tenant, '16x16');
  const link32x32 = createIconLink(tenant, '32x32');

  document.head.appendChild(link16x16);
  document.head.appendChild(link32x32);
}

/**
  * Dynamically changing favicons by tenant
  *
  * @param {string} tenant - The current tenant
  */
export default function changeFaviconByTenant(tenant) {
  removeOldIcons();
  addNewIcons(tenant);
}
