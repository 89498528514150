const getFeaturesInfo = (state) => state.featuresInfo;
const getPromosRoles = (state) => state.featuresInfo.promos.authorize;
const getReportRoles = (state) => state.featuresInfo.report.authorize;
const getDashboardRoles = (state) => state.featuresInfo.dashboard.authorize;
const isPromosEnabled = (state) => state.featuresInfo.promos.isEnabled;

export default {
  getFeaturesInfo,
  getDashboardRoles,
  getPromosRoles,
  getReportRoles,
  isPromosEnabled,
};
