import routesNames from '@/router/routes-names';

const { exchange } = routesNames;
const { campaigns } = exchange;

const Campaigns = () => import(/* webpackChunkName: "async-xc-campaigns" */ '@/views/exchange/campaigns/campaigns.vue');

/**
 * @description Get exchange campaigns route
 * @returns {Array<Object>}
 */
export default [
  {
    component: Campaigns,
    path: `/${exchange.main}/${campaigns.main}`,
    name: `${exchange.main}.${campaigns.main}`,
  },
];
