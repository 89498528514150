<template>
  <v-list>
    <!--Title-->
    <v-subheader class="text-uppercase">
      {{ $t('app.search.title') }}
    </v-subheader>

    <!--Search input-->
    <v-list-item>
      <v-text-field
        v-model.trim="pnr"
        autofocus
        :disabled="!getEnabled"
        :append-icon="$t('app.icons.search')"
        :placeholder="$t('pnr.search.input.label')"
        data-test="clmbs-sear-by-pnr"
        @click:append="handleSearch"
        @keyup.enter="handleSearch"
      />
    </v-list-item>

    <RecentSearches />
  </v-list>
</template>

<script>
import isNil from 'lodash.isnil';
import { mapActions, mapGetters } from 'vuex';
import routesNames from '@/router/routes-names';
import storeConstants from '@/constants/store';
import RecentSearches from '@/components/search-by-pnr/search-box/recent-searches/recent-searches.vue';

const { APP } = storeConstants;
const { search } = routesNames;

export default {
  name: 'SearchBox',

  components: {
    RecentSearches,
  },

  data() {
    return {
      pnr: null,
    };
  },

  computed: {
    ...mapGetters({
      storedPNR: `${APP.SEARCH}/storedPNR`,
      getEnabled: `${APP.SEARCH}/getEnabled`,
      userHasAccessToUpsells: `${APP.USER_INFO}/hasAccessToUpsells`,
      userHasAccessToExchange: `${APP.USER_INFO}/hasAccessToExchange`,
    }),

    showSearchField() {
      return this.userHasAccessToUpsells || this.userHasAccessToExchange;
    },
  },

  methods: {
    ...mapActions({
      saveSearch: `${APP.SEARCH}/save`,
      disabledSearch: `${APP.SEARCH}/disabled`,
      setContextualType: `${APP.MENU}/setContextualType`,
    }),

    /**
     * Process the search
     * The new PNR must be different to:
     *  - The stored PNR on Vuex
     *  - The current route PNR param
     */
    mustProcessSearch() {
      return (
        this.pnr !== this.storedPNR
        || this.pnr !== this.$route.params.pnr
      );
    },

    /**
     * Handle search
     */
    async handleSearch() {
      if (!isNil(this.pnr) && this.mustProcessSearch()) {
        this.setContextualType(null);

        await this.disabledSearch();
        await this.saveSearch(this.pnr);

        this.changeRouterPath();
      }

      this.resetSearch();
    },

    /**
     * Change router path
     */
    changeRouterPath() {
      this.$router.push({
        name: `${search.main}.${search.pnr.main}`,
        params: {
          pnr: this.pnr,
        },
      });
    },

    /**
     * Reset search
     */
    resetSearch() {
      this.pnr = null;
    },
  },
};
</script>
