import types from '@/store/app/search/utils/types';

const {
  SAVE,
  ENABLED,
  DISABLED,
  RESET,
  LIST,
} = types;

/**
 * Check if the item was previously saved
 *
 * @param {Array<Object>} list - List of items stored
 * @param {String} pnr - PNR to add to the list
 */
function checkPreviouslySaved(list, pnr) {
  return list.some((item) => item.pnr === pnr);
}

/**
 * Check the limit of items to save
 *
 * @param {Array<Object>} list - List of items stored
 * @param {String} pnr - PNR to add to the list
 */
function checkLimit(list) {
  const limit = 6;
  return list.length === limit;
}

export default {
  [SAVE](state, payload) {
    state.pnr = payload;
  },

  [ENABLED](state) {
    state.enabled = true;
  },

  [DISABLED](state) {
    state.enabled = false;
  },

  [RESET](state) {
    state.pnr = null;
  },

  /**
   * Add the item to the list of recent searches
   *
   * @param {Object} state - The state of the current module
   * @param {Object} payload - The item to save (pnr and status)
   */
  [LIST](state, payload) {
    const previouslySaved = checkPreviouslySaved(state.list, payload.pnr);

    if (!previouslySaved) {
      if (checkLimit(state.list)) {
        const [, ...tail] = state.list;
        state.list = [...tail, payload];
      } else {
        state.list = [...state.list, payload];
      }
    }
  },
};
