// Routes for Settings component

export default {
  main: 'settings',
  routes: {
    main: 'routes',
    list: 'list',
    create: 'create',
  },
  groups: {
    main: 'groups',
    list: 'list',
    create: 'create',
  },
  users: {
    main: 'users',
    edit: 'edit',
    create: 'create',
    roles: {
      all: 'all',
      super: 'super-admin',
      admin: 'editor',
      creator: 'support',
      viewer: 'analyst',
      supervisor: 'supervisor',
    },
  },
  logs: {
    main: 'logs',
    list: 'list',
  },
};
