<template>
  <v-list-item
    :class="{ 'pl-0': !mini }"
    to="/"
    class="pb-2 pt-2 white logo side-nav-bar-logo"
  >
    <v-img
      :src="logo"
      :lazy-src="imagePlaceholder()"
      max-width="230"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import { getLogo } from '@/utils/theme';
import storeConstants from '@/constants/store';
import imagePlaceholder from '@/utils/imagePlaceholder';

const { APP } = storeConstants;

export default {
  name: 'SideNavBarLogo',

  data() {
    return {
      imagePlaceholder,
    };
  },

  computed: {
    ...mapGetters({
      mini: `${APP.MENU}/sideNavBarMini`,
      getTenant: `${APP.USER_INFO}/getTenant`,
    }),

    logo() {
      return getLogo(this.getTenant, this.mini);
    },
  },
};
</script>
<style lang="scss">
.side-nav-bar-logo {
  &.v-list-item--link.logo:before {
    background-color: transparent;
  }
}
</style>
