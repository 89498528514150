<template>
  <v-list
    nav
    dense
  >
    <Logo />
    <Menu />
  </v-list>
</template>

<script>
import Logo from '@/components/app/side-navbar/top/logo/logo.vue';
import Menu from '@/components/app/side-navbar/top/menu/menu.vue';

export default {
  name: 'SideNavBarTop',

  components: {
    Logo,
    Menu,
  },
};
</script>
