import routesNames from '@/router/routes-names';

const { upsells } = routesNames;

const Dashboard = () => import(/* webpackChunkName: "async-up-dashboard" */ '@/views/upsells/dashboard/dashboard.vue');

/**
 * @description Get upsells dashboard route
 * @returns {Array<Object>}
 */
export default [
  {
    component: Dashboard,
    path: `/${upsells.main}/${upsells.dashboard.main}`,
    name: `${upsells.main}.${upsells.dashboard.main}`,
  },
  {
    component: Dashboard,
    path: `/${upsells.main}/${upsells.dashboard.main}/:tenant`,
    name: `${upsells.main}.${upsells.dashboard.main}.tenant`,
    props: true,
  },
];
