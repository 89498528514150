import axios from 'axios';
import appConstants from '@/constants/app';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapDashboardParametersToQuery from '@/middleware/api/utils/mapDashboardParametersToQuery';

const { cms } = endpoints;

/**
 * Get IATA code
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @param {String} code - The IATA code to lookup
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#cms_iata_airports__code__get }
 */
async function getIATACode({ token, userInfo, code = '' }) {
  try {
    const url = `${cms.main}/${cms.iata.main}/${cms.iata.airports}/${code}`;
    const response = await axios.get(url, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get dashboard configuration from CMS
 *
 * @async
 * @param {Object} payload - The payload
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @param {String} payload.product - The product id (up, xc, bot)
 * @param {String} payload.tenant - The tenant code (D7, XJ, etc)
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#cms_charts_get }
*/
async function getDashboard(payload) {
  const {
    token,
    userInfo,
  } = payload;

  const url = `${cms.main}/${cms.charts}`;
  const query = mapDashboardParametersToQuery(payload);

  try {
    const response = await axios.get(`${url}?${query}`, axiosConfiguration(token));
    const { config } = response.data.data.value;

    return JSON.parse(config);
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get bot messages query
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#cms_messages_get }
 */
async function getBotMessagesQuery({ token, userInfo }) {
  const { CHATBOT } = appConstants.PRODUCTS;
  const query = `product=${CHATBOT}`;

  try {
    const url = `${cms.main}/${cms.messages}?${query}`;
    const response = await axios.get(url, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get intents dictionary
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#cms_intents_dictionary_get }
 */
async function getIntentsDictionary({ token, userInfo }) {
  try {
    const url = `${cms.main}/${cms.intentsDictionary}/`;
    const response = await axios.get(url, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  cms: {
    getIATACode,
    getDashboard,
    getIntentsDictionary,
    getBotMessagesQuery,
  },
};
