import appConstants from '@/constants/app';

const { PRODUCTS } = appConstants;
const { UPGRADES, EXTRA_SEATS } = PRODUCTS;

/**
 * @description Ensure is a valid bid product
 * @param {String} value
 * @returns {Boolean}
 */
export default function isValidBidProduct(product) {
  const options = [UPGRADES, EXTRA_SEATS];
  return options.indexOf(product.toLowerCase()) !== -1;
}
