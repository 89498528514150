import app from './app';
import cms from './cms';
import logs from './logs';
import users from './users';
import alerts from './alerts';
import upsells from './upsells';
import profile from './profile';
import chatbot from './chatbot';
import subscriptions from './subscriptions';
import couponpass from './couponpass';
import exchange from './exchange';
import masterdata from './masterdata';
import giftVoucher from './gift-voucher';
import reports from './reports';

export default ({
  ...app,
  ...cms,
  ...logs,
  ...users,
  ...alerts,
  ...upsells,
  ...profile,
  ...chatbot,
  ...subscriptions,
  ...couponpass,
  ...exchange,
  ...masterdata,
  ...giftVoucher,
  ...reports,
});
