import programConstants from '@/constants/program';

const { PROGRAM } = programConstants;
const { ACTIVE, INACTIVE, CLOSED } = PROGRAM.STATUS;

/**
 * @description Ensure that status is valid
 * @param {String} value
 * @returns {Boolean}
 */
export default function isValidStatus(status) {
  const options = [ACTIVE, INACTIVE, CLOSED];
  return options.indexOf(status) !== -1;
}
