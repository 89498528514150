export default function initialState() {
  return {
    id: '',
    role: '',
    name: '',
    email: '',
    tenant: '',
    picture: '',
    products: [],
  };
}
