// Routes names for Gift Voucher product

export default {
  main: 'gift-voucher',
  dashboard: {
    main: 'dashboard',
  },
  requests: {
    main: 'voucher-requests',
  },
};
