import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const {
  masterData,
  currentDate,
  updateUserLoginInfo,
} = endpoints;

/**
 * Get master data
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#master_data_get }
 */
async function getMasterData({ token, userInfo }) {
  try {
    const response = await axios.get(masterData.main, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get current date
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#current_date_get }
 */
async function getCurrentDate({ token, userInfo }) {
  try {
    const response = await axios.get(currentDate, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Update user login data
 *
 * @async
 * @param {String} token - The access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#current_date_get }
 */
async function updateLoginInfo({ token, userInfo }) {
  try {
    const response = await axios.post(updateUserLoginInfo, {}, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  app: {
    getMasterData,
    getCurrentDate,
    updateLoginInfo,
  },
};
