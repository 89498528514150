import routesNames from '@/router/routes-names';

const { giftVoucher } = routesNames;

const Dashboard = () => import(/* webpackChunkName: "async-gv-dashboard" */ '@/views/gift-voucher/dashboard/dashboard.vue');

/**
 * @description Get gift voucher dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: Dashboard,
  path: `/${giftVoucher.main}/${giftVoucher.dashboard.main}`,
  name: `${giftVoucher.main}.${giftVoucher.dashboard.main}`,
}];
