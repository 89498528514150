import appConstants from '@/constants/app';

const { EXPIRE_AT_SESSION } = appConstants.AUTH;

/**
 * @description Save expiration date on localstorage
 */
export default async function saveExpireAtSession() {
  /**
   * Expiration date on seconds
   * For change this value, go to:
   * -> Auth0 dashboard -> Applications -> Columbus -> JWT Expiration
   */
  const expirationDate = 7200;

  // Convert expiration date to miliseconds
  // and sum to the current time
  const expireAt = (expirationDate * 1000) + new Date().getTime();

  // Save expiration date on miliseconds
  await localStorage.setItem(EXPIRE_AT_SESSION, expireAt);

  return true;
}
