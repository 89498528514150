import routesNames from '@/router/routes-names';

const { settings } = routesNames;

function Users() {
  return import(/* webpackChunkName: "async-settings-users" */ '@/views/settings/users/users.vue');
}

function AllList() {
  return import(/* webpackChunkName: "async-settings-all-users" */ '@/views/settings/users/list/all/all.vue');
}

function SuperList() {
  return import(/* webpackChunkName: "async-settings-super-users" */ '@/views/settings/users/list/super/super.vue');
}

function AdminList() {
  return import(/* webpackChunkName: "async-settings-admin-users" */ '@/views/settings/users/list/admin/admin.vue');
}

function CreatorList() {
  return import(/* webpackChunkName: "async-settings-creator-users" */ '@/views/settings/users/list/creator/creator.vue');
}

function ViewerList() {
  return import(/* webpackChunkName: "async-settings-viewer-users" */ '@/views/settings/users/list/viewer/viewer.vue');
}

function SupervisorList() {
  return import(/* webpackChunkName: "async-settings-viewer-users" */ '@/views/settings/users/list/supervisor/supervisor.vue');
}

function Create() {
  return import(/* webpackChunkName: "async-settings-new-user" */ '@/views/settings/users/create/create.vue');
}

function Edit() {
  return import(/* webpackChunkName: "async-settings-edit-user" */ '@/views/settings/users/edit/edit.vue');
}

export default {
  path: `${settings.users.main}`,
  component: Users,
  redirect: `${settings.users.main}/${settings.users.roles.all}`,
  children: [
    // Role: All
    {
      path: `${settings.users.roles.all}`,
      component: AllList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.all}`,
    },
    // Role: Super Admin
    {
      path: `${settings.users.roles.super}`,
      component: SuperList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.super}`,
    },
    // Role: Admin
    {
      path: `${settings.users.roles.admin}`,
      component: AdminList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.admin}`,
    },
    // Role: Creator
    {
      path: `${settings.users.roles.creator}`,
      component: CreatorList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.creator}`,
    },
    // Role: Viewer
    {
      path: `${settings.users.roles.viewer}`,
      component: ViewerList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.viewer}`,
    },
    // Role: Supervisor
    {
      path: `${settings.users.roles.supervisor}`,
      component: SupervisorList,
      name: `${settings.main}.${settings.users.main}.${settings.users.roles.supervisor}`,
    },
    // Create User
    {
      path: `${settings.users.create}`,
      component: Create,
      name: `${settings.main}.${settings.users.main}.${settings.users.create}`,
    },
    // Edit User
    {
      path: `${settings.users.edit}/:id`,
      component: Edit,
      name: `${settings.main}.${settings.users.main}.${settings.users.edit}`,
    },
  ],
};
