import axios from 'axios';
import isNil from 'lodash.isnil';
import endpoints from '@/middleware/api/utils/endpoints';
import isValidStatus from '@/middleware/api/utils/isValidStatus';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapCampaignParametersToQuery from '@/middleware/api/utils/mapCampaignParametersToQuery';
import mapSearchFilterParametersToQuery from '@/middleware/api/utils/mapSearchFilterParametersToQuery';

const { campaigns } = endpoints.exchange;

/**
 * @description Get campaign
 * @param {String} id - id campaign
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_campaigns__id__get}
 */
async function getCampaign(payload) {
  const { id, token, userInfo } = payload;

  try {
    const response = await axios.get(`${campaigns}/${id}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get campaigns
 * @param {Object} params - parameters for requesting a specific list of campaigns
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_campaigns_get}
 */
async function getCampaigns(payload) {
  const { params, token, userInfo } = payload;

  try {
    const queryStrings = [
      mapCampaignParametersToQuery(params),
      mapSearchFilterParametersToQuery(params),
    ];
    const query = queryStrings.join('&');
    const response = await axios.get(`${campaigns}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Update the status of a campaign
 * @param {String} id - campaign id
 * @param {String} status - campaign status
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_campaigns__id__patch}
*/
async function updateStatus(payload) {
  const {
    id,
    status,
    token,
    userInfo,
  } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.campaign.id'));
  }

  if (!isValidStatus(status)) {
    return Promise.reject(new Error('app.error.status'));
  }

  try {
    const response = await axios.patch(`${campaigns}/${id}`, { status }, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getCampaign,
  getCampaigns,
  updateStatus,
};
