import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { isEnvDevelopment } from '@/utils/getEnvironments';

/**
 * @description Initialize Sentry
 * @link https://docs.sentry.io/platforms/javascript/vue/
 */
export default function sentry() {
  if (!isEnvDevelopment) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_URL,
      release: process.env.VUE_APP_RELEASE,
      environment: process.env.VUE_APP_ENV,
      integrations: [new VueIntegration({
        Vue,
        attachProps: true,
      })],
    });
  }
}
