<template>
  <v-list-item
    v-if="displayAlerts"
    :title="$t('app.sideNavbar.smartAlerts.title')"
    :to="getLink()"
    @click="handleContextualType()"
  >
    <v-list-item-action>
      <v-badge
        :content="count"
        :value="count"
        color="orange"
        overlap
      >
        <v-icon>
          {{ $t('app.icons.bell') }}
        </v-icon>
      </v-badge>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        {{ $t('app.sideNavbar.smartAlerts.title') }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';
import storeAlerts from '@/store/app/alerts';
import RegisterStoreModule from '@/components/mixins/RegisterModule/RegisterModule';

const { APP } = storeConstants;
const { STATUS } = appConstants;

const { smartAlerts } = routesNames;

export default {
  name: 'SideNavBarSmartAlerts',

  mixins: [RegisterStoreModule],

  data() {
    return {
      count: 0,
    };
  },

  computed: {
    ...mapGetters({
      programsExpired: `${APP.ALERTS}/programs`,
      displayAlerts: `${APP.USER_INFO}/displayExchangeAlerts`,
    }),
  },

  created() {
    /**
     * In order to display the Exchange alerts
     * the user must be super-admin or admin
     * and must have access to Exchange
     */
    if (this.displayAlerts) {
      this.handleRequestPrograms();
    }
  },

  methods: {
    ...mapActions({
      showSnackbar: `${APP.SNACKBAR}/show`,
      setContextualType: `${APP.MENU}/setContextualType`,
      requestProgramsExpired: `${APP.ALERTS}/requestPrograms`,
    }),

    /**
     * Get link
     *
     * @returns {String}
     */
    getLink() {
      return `/${smartAlerts.main}`;
    },

    /**
     * Handle contextual type
     */
    handleContextualType() {
      this.setContextualType(null);
    },

    /**
     * Handle request programs
     */
    async handleRequestPrograms() {
      try {
        const days = 30;

        // Register the alerts Vuex module
        await this.registerStoreModule(APP.ALERTS, storeAlerts);

        // Request the programs that will be expired in the next 30 days
        await this.requestProgramsExpired(days);

        // Once the above request finish, get the number of the programs expired
        this.count = this.programsExpired.length;

        // Only diplay the snack bar if
        // there is at least one program that will be expired
        if (this.count > 0) {
          this.showSnackbar({
            message: this.$t('alerts.snackbar.message', {
              days: this.days,
              count: this.count,
            }),
            status: STATUS.ALERTS,
          });
        }
      } catch (error) {
        // In this case, the mixin must fail silently
      }
    },
  },
};
</script>
