export default function initialState() {
  return {
    mini: true,
    contextual: {
      type: null,
    },
    profile: {
      show: false,
      topPosition: 0,
    },
  };
}
