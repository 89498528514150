import routesNames from '@/router/routes-names';
import Home from '@/views/home/home.vue';
import Logout from '@/views/logout/logout.vue';
import Callback from '@/views/callback/callback.vue';

const {
  home,
  callback,
  logout,
} = routesNames;

/**
 * @description Get app routes
 * @returns {Array<Object>}
 */
export default [
  {
    path: '/',
    component: Home,
    name: home,
  },
  {
    path: `/${callback}`,
    component: Callback,
    name: callback,
  },
  {
    path: `/${logout}`,
    component: Logout,
    name: logout,
  },
  {
    path: '*',
    redirect: '/',
  },
];
