/**
 * Vue Router - Errors
 * Vue register a callback which will be called
 * when an error is caught during a route navigation.
 */
export default function routerOnError(error) {
  /**
   * CLMBS-911:
   * - The chunk might no longer be available due to a recent redeployment of the page
   * - Mark the page to don't trigger reload infinitely
   */
  const pattern = /^ChunkLoadError/;
  const isChunkLoadFailed = error.message.match(pattern);

  if (isChunkLoadFailed && window.location.hash !== '#retry') {
    window.location.hash = '#retry';
    window.location.reload();
  }
}
