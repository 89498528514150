import logsConstants from '@/constants/logs';

const { PAGINATION } = logsConstants.LOGS;
const { ORDER } = PAGINATION;

/**
 * @description Get pagination order
 * @param {Boolean} order
 * @returns {String}
 */
const getOrder = (order) => {
  if (order) {
    return ORDER.ASC;
  }

  return ORDER.DESC;
};

/**
 * @description Create a query parameters for requesting a list of logs
 * @param {Object} params - parameters for requesting a specific list of logs
 * @returns {String} params queries for API
 * @example: page=1&per_page=10&sort=date&order=desc
 */
export default function mapLogParametersToQuery(params) {
  const {
    page,
    sort,
    order,
    perPage,
  } = params;

  const query = [
    `page=${page}`,
    `sort=${sort}`,
    `per_page=${perPage}`,
    `order=${getOrder(order)}`,
  ];

  return query.join('&');
}
