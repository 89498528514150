import appConstants from '@/constants/app';

const { TENANT, ROLES, PRODUCTS } = appConstants;

const {
  SUPER,
  ADMIN,
  SUPERVISOR,
  CREATOR,
  VIEWER,
} = ROLES;

const {
  EXCHANGE,
  UPSELLS,
  CHATBOT,
  SUBSCRIPTIONS,
  COUPONPASS,
  GIFT_VOUCHER,
} = PRODUCTS;

/**
 * Checks if the user has an super admin role
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const isUserSuper = (state) => state.role === SUPER;

/**
 * Checks if the user has a supervisor role
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const isUserSupervisor = (state) => state.role === SUPERVISOR;

/**
 * Checks if the user has an admin role
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const isUserAdmin = (state) => state.role === ADMIN;

/**
 * Checks if the user is an analyst (viewer)
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const isUserViewer = (state) => state.role === VIEWER;
/**
 * Getter for user id
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getUserId = (state) => state.id;

/**
 * Getter for user name
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getUserName = (state) => state.name;

/**
 * Getter for user role
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getUserRole = (state) => state.role;

/**
 * Getter for user email
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getUserEmail = (state) => state.email;

/**
 * Getter for user picture
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getUserPicture = (state) => state.picture;

/**
 * Getter for user products
 * @param {Object} state - state from UserInfo module
 * @returns {Array}
 */
const getUserProducts = (state) => state.products;

/**
 * Getter tenant
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const getTenant = (state) => state.tenant;

/**
 * Checks if the user has access to exchange product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToExchange = (state) => state.products.includes(EXCHANGE);

/**
 * Checks if the user has access to upsells product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToUpsells = (state) => state.products.includes(UPSELLS);

/**
 * Checks if the user has access to chatbot product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToChatbot = (state) => {
  if (state.tenant === TENANT['0B']) {
    return true;
  }
  return state.products.includes(CHATBOT);
};

/**
 * Checks if the user has access to subscriptions product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToSubscriptions = (state) => state.products.includes(SUBSCRIPTIONS);

/**
 * Checks if the user has access to couponpass product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToCouponPass = (state) => state.products.includes(COUPONPASS);

/**
 * Checks if the user has access to gift voucher product
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToGiftVoucher = (state) => state.products.includes(GIFT_VOUCHER);

/**
 * Checks if the user has enough privileges to apply actions or view data
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasUserEnoughPrivileges = (state) => {
  const { role } = state;

  return (
    role === SUPER
    || role === ADMIN
    || role === CREATOR
  );
};

/**
 * Checks if the user admin privileges
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasUserAdminPrivileges = (state) => {
  const { role } = state;

  return (
    role === SUPER
    || role === ADMIN
    || role === SUPERVISOR
  );
};

/**
 * In order to display the Exchange alerts
 * the user must be super-admin or admin
 * and must have access to Exchange
 *
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const displayExchangeAlerts = (state) => hasUserAdminPrivileges(state) && hasAccessToExchange(state);

/**
 * In order to display the Gift Voucher report
 * the user must be super-admin or admin
 * and must have access to Exchange
 *
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const hasAccessToGiftVoucherReport = (state) => hasUserAdminPrivileges(state) && hasAccessToGiftVoucher(state);

export default {
  isUserSuper,
  isUserAdmin,
  isUserSupervisor,
  isUserViewer,

  getUserId,
  getTenant,
  getUserRole,
  getUserName,
  getUserEmail,
  getUserPicture,
  getUserProducts,

  hasAccessToExchange,
  hasAccessToUpsells,
  hasAccessToChatbot,
  hasAccessToSubscriptions,
  hasAccessToCouponPass,
  hasAccessToGiftVoucher,
  hasUserEnoughPrivileges,
  hasUserAdminPrivileges,

  displayExchangeAlerts,
  hasAccessToGiftVoucherReport,
};
