import routesNames from '@/router/routes-names';

const { exchange } = routesNames;
const { programs } = exchange;

function Programs() {
  return import(/* webpackChunkName: "async-xc-programs" */ '@/views/exchange/programs/programs.vue');
}

function ProgramsActive() {
  return import(/* webpackChunkName: "async-xc-programs-active" */ '@/views/exchange/programs/table/active/active.vue');
}

function ProgramsInactive() {
  return import(/* webpackChunkName: "async-xc-programs-inactive" */ '@/views/exchange/programs/table/inactive/inactive.vue');
}

function ProgramsClosed() {
  return import(/* webpackChunkName: "async-xc-programs-closed" */ '@/views/exchange/programs/table/closed/closed.vue');
}

function ImportPrograms() {
  return import(/* webpackChunkName: "async-xc-programs-import" */ '@/views/exchange/programs/import/import.vue');
}

/**
 * @description Get exchange programs routes
 * @returns {Array<Object>}
 */
export default [
  {
    path: `/${exchange.main}/${programs.main}`,
    component: Programs,
    redirect: `${exchange.main}/${programs.main}/${programs.active}`,
    children: [
      {
        path: `${programs.active}`,
        component: ProgramsActive,
        name: `${exchange.main}.${programs.main}.${programs.active}`,
      },
      {
        path: `${programs.inactive}`,
        component: ProgramsInactive,
        name: `${exchange.main}.${programs.main}.${programs.inactive}`,
      },
      {
        path: `${programs.closed}`,
        component: ProgramsClosed,
        name: `${exchange.main}.${programs.main}.${programs.closed}`,
      },
    ],
  },
  {
    component: ImportPrograms,
    path: `/${exchange.main}/${programs.main}/${programs.import}`,
    name: `${exchange.main}.${programs.main}.${programs.import}`,
  },
];
