import Keycloak from 'keycloak-js';

const initOptions = {
  url: process.env.VUE_APP_AUTH_DOMAIN,
  realm: process.env.VUE_APP_AUTH_REALM,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  onLoad: process.env.VUE_APP_AUTH_APP_ONLOAD,
};

export default async function keycloakClient() {
  const keycloak = await new Keycloak(initOptions);
  return keycloak;
}
