export default {
  APP: {
    MENU: 'appMenu',
    USERS: 'appUsers',
    ALERTS: 'appAlerts',
    SEARCH: 'appSearch',
    SPINNER: 'appSpinner',
    SNACKBAR: 'appSnackbar',
    USER_INFO: 'appUserInfo',
    MASTERDATA: 'appMasterData',
    SERVER_DATE: 'appServerDate',
    MULTI_SEARCH: 'appMultiSearch',
    CONFIRM_DIALOG: 'appConfirmDialog',
    UPDATE_LOGIN_INFO: 'appUpdateLoginInfo',
    LOGS: 'appLogs',
    FEATURES: 'appFeatures',
  },
  COMMONS: {
    DASHBOARD: 'commonsDashboard',
    IATA_CODES: 'commonsIATACodes',
    CONFIGURATION: 'commonsConfiguration',
    INTENTS_TOPICS: 'commonsIntentsTopics',
  },
  EXCHANGE: {
    OFFERS: 'exchangeOffers',
    PROGRAM: 'exchangeProgram',
    PROGRAMS: 'exchangePrograms',
    CAMPAIGNS: 'exchangeCampaigns',
    CONFIGURATION: 'exchangeConfiguration',
  },
  UPSELLS: {
    BIDS: 'upsellsBids',
    PROGRAM: 'upsellsProgram',
    PROGRAMS: 'upsellsPrograms',
  },
  GIFT_VOUCHERS: {
    VOUCHER_REQUESTS: 'giftVoucherBids',
  },
  REPORTS: {
    REPORTS_LIST: 'reportsList',
  },
  CHATBOT: {
    SETTINGS: 'chatbotSettings',
    USERS: 'chatUsers',
  },
  SUBSCRIPTIONS: {
    USERS: 'subsUsers',
    PROMOS: 'subsPromos',
  },
  COUPONPASS: {
    USERS: 'couponUsers',
  },
};
