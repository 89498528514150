/**
 * @description Get query for page and result per page
 * @param {Number} params.page
 * @param {Number} params.perPage
 * @returns {Array} Filter by page and result per page
 */
function queryPageAndPerPage(params) {
  const query = [];

  const {
    page,
    perPage,
  } = params;

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  return query;
}

function queryVoucher(params) {
  const query = [];

  const {
    voucherStatus,
    contactEmail,
    contactName,
  } = params;

  if (voucherStatus) {
    query.push(`voucherStatus=${voucherStatus}`);
  }

  if (contactEmail) {
    query.push(`contactEmail=${contactEmail}`);
  }

  if (contactName) {
    query.push(`contactName=${contactName}`);
  }

  return query;
}

/**
 * @description Get query payment
 * @param {String} params.paymentStatus
 * @param {String} params.paymentReference
 * @param {String} params.paymentStartDate
 * @param {String} params.paymentEndDate
 * @returns {Array} Filter by dates
 */
function queryPayment(params) {
  const query = [];

  const {
    paymentStatus,
    paymentReference,
    paymentStartDate,
    paymentEndDate,
  } = params;

  if (paymentStatus) {
    query.push(`status=${paymentStatus}`);
  }

  if (paymentReference) {
    query.push(`paymentReference=${paymentReference}`);
  }

  if (paymentStartDate) {
    query.push(`paymentStartDate=${paymentStartDate}`);
  }

  if (paymentEndDate) {
    query.push(`paymentEndDate=${paymentEndDate}`);
  }

  return query;
}

/**
 * @description Create a query parameters for requesting a list of requests
 * @param {Object} params - parameters for requesting a specific list of requests
 * @example: page=1&per_page=10
 * @returns {String} params queries for API
 */
export default function mapGiftVoucherParametersToQuery(params) {
  const query = [
    ...queryPageAndPerPage(params),
    ...queryVoucher(params),
    ...queryPayment(params),
  ];

  return query.join('&');
}
