import routesNames from '@/router/routes-names';

const { settings } = routesNames;

function Groups() {
  return import(/* webpackChunkName: "async-settings-groups" */ '@/views/settings/groups/groups.vue');
}

function List() {
  return import(/* webpackChunkName: "async-settings-groups-list" */ '@/views/settings/groups/list/list.vue');
}

function Create() {
  return import(/* webpackChunkName: "async-settings-groups-create" */ '@/views/settings/groups/create/create.vue');
}

export default {
  path: `${settings.groups.main}`,
  component: Groups,
  children: [
    {
      path: '',
      component: List,
      name: `${settings.main}.${settings.groups.main}.${settings.groups.list}`,
    },
    {
      path: `${settings.groups.create}`,
      component: Create,
      name: `${settings.main}.${settings.groups.main}.${settings.groups.create}`,
    },
  ],
};
