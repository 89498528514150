import types from '@/store/app/search/utils/types';

const {
  SAVE,
  ENABLED,
  DISABLED,
  RESET,
  LIST,
} = types;

/**
 * @description Save PNR
 * @param {Function} commit from Vuex
 * @param {String} payload - PNR to save
 */
function save({ commit }, payload) {
  commit(SAVE, payload);
}

/**
 * @description Add the item to the list of recent searches
 * @param {Function} commit from Vuex
 * @param {Object} payload - The item to save (pnr and status)
 */
function addToRecentSearches({ commit }, payload) {
  commit(LIST, payload);
}

/**
 * @description Enable search
 * @param {Function} commit from Vuex
 */
function enabled({ commit }) {
  commit(ENABLED);
}

/**
 * @description Disabled search
 * @param {Function} commit from Vuex
 */
function disabled({ commit }) {
  commit(DISABLED);
}

/**
 * @description Resets the value of PNR
 * @param {Function} commit from Vuex
 */
function reset({ commit }) {
  commit(RESET);
}

export default {
  save,
  enabled,
  disabled,
  reset,
  addToRecentSearches,
};
