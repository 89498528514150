import dateFormat from '@/filters/dateFormat';
import dateHourFormat from '@/filters/dateHourFormat';
import capitalize from '@/filters/capitalize';
import currencyFormat from '@/filters/currencyFormat';

export default {
  install(Vue) {
    Vue.filter('dateFormat', dateFormat);
    Vue.filter('dateHourFormat', dateHourFormat);
    Vue.filter('capitalize', capitalize);
    Vue.filter('currencyFormat', currencyFormat);
  },
};
