import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';

// App
const { PRODUCTS } = appConstants;
const { GIFT_VOUCHER } = PRODUCTS;

// Routes
const { giftVoucher } = routesNames;

// i18n
const product = `app.products.${GIFT_VOUCHER}`;

/**
 * @description Gift Voucher data
 * @this Vue component
 * @returns {Object}
 */
export default function giftVoucherData() {
  return {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, GIFT_VOUCHER),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description.${this.getTenant}`),
    sections: [
      {
        icon: this.$t('app.icons.dashboard'),
        title: this.$t(`${product}.sections.dashboard.title`),
        link: `/${giftVoucher.main}/${giftVoucher.dashboard.main}`,
      },
      {
        icon: this.$t('app.icons.dashboard'),
        title: this.$t('giftvouchers.title'),
        link: `/${giftVoucher.main}/${giftVoucher.requests.main}`,
      },
    ],
  };
}
