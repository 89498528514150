import routesNames from '@/router/routes-names';

const { profile } = routesNames;

function Profile() {
  return import(/* webpackChunkName: "async-profile" */ '@/views/profile/profile.vue');
}

export default [{
  component: Profile,
  path: `/${profile.main}`,
  name: `${profile.main}`,
}];
