import bids from './bids';
import programs from './programs';
import configuration from './configuration';

export default {
  upsells: {
    bids,
    programs,
    configuration,
  },
};
