export default {
  OFFERS: {
    STATUS: {
      CLOSED: 'CLOSED',
      FAILED: 'FAILED',
      EXPIRED: 'EXPIRED',
      CREATED: 'CREATED',
      ACCEPTED: 'ACCEPTED',
      EXECUTION_FAILED: 'EXECUTION_FAILED',
    },
    REWARDS: {
      TYPES: {
        MILES: 'miles',
        VOUCHER: 'voucher',
      },
    },
    EVENTS: {
      OFFER_UPDATE: 'offer-update',
      ISSUE_REWARD: 'issue-reward',
    },
  },
};
