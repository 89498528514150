import types from '@/store/app/menu/utils/types';

const {
  TOGGLE_MINI,
  SET_CONTEXTUAL_TYPE,

  TOGGLE_PROFILE,
  SET_PROFILE_TOP_POSITION,
} = types;

/**
 * Toggle side navegation bar - mini option
 *
 * @param {Object} context - context from Vuex
 * @param {Function} context.commit from Vuex
 * @returns {Promise}
 */
function toggleSideNavBarMini({ commit }) {
  commit(TOGGLE_MINI);
}

/**
 * Set contextual type
 *
 * @param {Object} context - context from Vuex
 * @param {Function} context.commit - commit from Vuex
 * @param {String|null} type - The type of the contextual menu
 * @returns {Promise}
 */
function setContextualType({ commit }, type) {
  commit(SET_CONTEXTUAL_TYPE, type);
}

/**
 * Toggle profile
 *
 * @param {Object} context - context from Vuex
 * @param {Function} context.commit - commit from Vuex
 * @returns {Promise}
 */
function toggleProfile({ commit }) {
  commit(TOGGLE_PROFILE);
}

/**
 * Set profile top position
 *
 * @param {Object} context - context from Vuex
 * @param {Function} context.commit - commit from Vuex
 * @param {Number} top - Top position of the profile
 * @returns {Promise}
 */
function setProfileTopPosition({ commit }, top) {
  commit(SET_PROFILE_TOP_POSITION, top);
}

export default {
  toggleSideNavBarMini,
  setContextualType,

  toggleProfile,
  setProfileTopPosition,
};
