/**
 * Get the bottom menu
 *
 * @this SideNavBarBottom
 */
export default function getBottomMenu() {
  return {
    settings: {
      icon: this.$t('app.icons.settings'),
      handler: this.handleSettings,
      title: this.$t('app.sideNavbar.settings.title'),
    },
    profile: {
      icon: this.$t('app.icons.user'),
      handler: this.handleProfile,
      title: this.$t('app.sideNavbar.profile.title'),
    },
    mini: {
      icon: this.$t('app.icons.chevronRight'),
      handler: this.handleSideNavBarMini,
      title: this.$t('app.sideNavbar.toggler.title'),
    },
  };
}
