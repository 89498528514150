/**
 * @description Get the stored PNR
 * @param {Object} state - state of Search module
 * @returns {String}
 */
const storedPNR = (state) => state.pnr;

/**
 * @description Check if the search is enabled
 * @param {Object} state - state of Search module
 * @returns {Boolean}
 */
const getEnabled = (state) => state.enabled;

/**
 * @description Get the list of pnr searched
 * @param {Object} state - state of Search module
 * @returns {Boolean}
 */
const getHistoryList = (state) => state.list;

export default {
  storedPNR,
  getEnabled,
  getHistoryList,
};
