<template>
  <div v-if="showRecentSearches">
    <v-subheader class="text-uppercase">
      {{ $t('pnr.search.historyList.recentSearches') }}
    </v-subheader>

    <v-list-item
      v-for="(item, index) in getHistoryList"
      :key="index"
    >
      <v-list-item-title>
        <v-btn
          text
          :to="getLink(item.pnr)"
          :disabled="isDisabled(item.status)"
          @click="setContextualType(null)"
        >
          <v-icon
            left
          >
            {{ getIcon(item.status) }}
          </v-icon>
          {{ item.pnr }}
        </v-btn>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import isEmpty from 'lodash.isempty';
import { mapActions, mapGetters } from 'vuex';
import appConstants from '@/constants/app';
import routesNames from '@/router/routes-names';
import storeConstants from '@/constants/store';

const { STATUS } = appConstants;
const { APP } = storeConstants;
const { search } = routesNames;

export default {
  name: 'RecentSearches',

  computed: {
    ...mapGetters({
      getHistoryList: `${APP.SEARCH}/getHistoryList`,
    }),

    showRecentSearches() {
      return !isEmpty(this.getHistoryList);
    },
  },

  methods: {
    ...mapActions({
      setContextualType: `${APP.MENU}/setContextualType`,
    }),

    /**
     * Check if the button is disabled
     *
     * @param {String} status
     * @returns {Boolean}
     * */
    isDisabled(status) {
      return status === STATUS.ERROR;
    },

    /**
     * Get icon
     *
     * @param {String} status
     * @returns {String}
     * */
    getIcon(status) {
      if (status === STATUS.OK) {
        return this.$t('app.icons.restore');
      }

      return this.$t('app.icons.error');
    },

    /**
     * Get the history link
     *
     * @param {String} pnr
     * @returns {String}
     */
    getLink(pnr) {
      return `/${search.main}/${search.pnr.main}/${pnr}`;
    },
  },
};
</script>
