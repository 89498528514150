import isNil from 'lodash.isnil';

/**
 * Side Nav Bar - mini option
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const sideNavBarMini = (state) => state.mini;

/**
 * Contextual menu - display
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const contextualDisplay = (state) => !isNil(state.contextual.type);

/**
 * Contextual type
 * @param {Object} state - state from UserInfo module
 * @returns {String}
 */
const contextualType = (state) => state.contextual.type;

/**
 * Profile - display
 * @param {Object} state - state from UserInfo module
 * @returns {Boolean}
 */
const profileDisplay = (state) => state.profile.show;

/**
 * Profile - top position
 * @param {Object} state - state from UserInfo module
 * @returns {Number}
 */
const profileTopPosition = (state) => state.profile.topPosition;

export default {
  sideNavBarMini,
  contextualDisplay,
  contextualType,
  profileDisplay,
  profileTopPosition,
};
