import routesNames from '@/router/routes-names';
import store from '@/store';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;
const reportRoles = store.getters[`${APP.FEATURES}/getReportRoles`];
const { reports } = routesNames;

const Reports = () => import(/* webpackChunkName: "async-reports" */ '@/views/reports/reports.vue');

/**
 * @description Get reports
 * @returns {Array<Object>}
 */
export default [{
  component: Reports,
  path: `/${reports.main}`,
  name: `${reports.main}`,
  meta: {
    authorize: reportRoles,
  },
}];
