/**
 * @description Vuex mutations types for search module
 */

const SAVE = 'save';
const ENABLED = 'enabled';
const DISABLED = 'disabled';
const RESET = 'reset';
const LIST = 'list';

export default {
  SAVE,
  ENABLED,
  DISABLED,
  RESET,
  LIST,
};
