import { format } from 'date-fns';
import localeEnUs from 'date-fns/locale/en-US';

/**
 * @description Get the formatted date string in the given format
 * @param {String} date - Date in ISO 8601 format. Example '2020-02-04T20:00:00.000Z'
 * @param {Number} date - Date on milliseconds. Example: 1522235100000
 * @returns {String|null} Date formatted (Example: '2018-11-08')
 */
export default function getDateFormatted(date) {
  const pattern = 'yyyy-MM-dd';
  return date ? format(new Date(date), pattern, { locale: localeEnUs }) : null;
}
