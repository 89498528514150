import isNil from 'lodash.isnil';
import types from '@/store/app/confirmDialog/utils/types';

const {
  SHOW,
  HIDE,
  CANCELATION,
  CONFIRMATION,
} = types;

/**
 * @description Get buttons
 * @param {Object} payload
 * @returns {Object}
 */
function getButtons(payload) {
  const { buttons } = payload;

  if (!isNil(buttons)) {
    const { cancelation, confirmation } = buttons;
    return {
      cancelation,
      confirmation,
    };
  }

  return {
    cancelation: '',
    confirmation: '',
  };
}

export default {
  [SHOW](state, payload = {}) {
    const {
      id,
      icon,
      text,
      title,
      countdown,
      dialogType,
      confirmCallback,
      cancelCallback,
      maxWidth,
    } = payload;

    state.show = true;
    state.id = id;
    state.text = text;
    state.title = title;
    state.icon = icon || null;
    state.buttons = getButtons(payload);
    state.countdown.show = isNil(countdown) ? false : countdown.show;
    state.dialogType = dialogType || null;
    state.confirmCallback = confirmCallback || null;
    state.cancelCallback = cancelCallback || null;
    state.maxWidth = maxWidth;
  },

  [HIDE](state) {
    state.show = false;
    state.text = '';
    state.title = '';
    state.icon = null;
    state.countdown.show = false;
    state.countdown.startTimeOnSeconds = 0;
    state.buttons.cancelation = '';
    state.buttons.confirmation = '';
    state.dialogType = null;
    state.confirmCallback = null;
    state.cancelCallback = null;
    state.maxWidth = null;
  },

  [CANCELATION](state) {
    state.canceled = !state.canceled;
  },

  [CONFIRMATION](state) {
    state.confirmed = !state.confirmed;
  },
};
