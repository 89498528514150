import api from '@/middleware/api';
import getApiConfiguration from '@/store/utils/getApiConfiguration';
import appConstants from '@/constants/app';

const { STATUS } = appConstants;

/**
 * @description Update current user's login info
 * @param {Object} rootGetters from Vuex
 * @returns {Promise}
 */
async function updateUserLoginInfo({ rootGetters }) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    await api.app.updateLoginInfo({ ...apiConfiguration });

    return STATUS.OK;
  } catch (error) {
    throw new Error('app.error.updateLoginInfo.request');
  }
}

export default {
  updateUserLoginInfo,
};
