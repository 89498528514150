import types from '@/store/app/userInfo/utils/types';

const {
  SAVE_ID,
  SAVE_ROLE,
  SAVE_NAME,
  SAVE_EMAIL,
  SAVE_TENANT,
  SAVE_PICTURE,
  SAVE_PRODUCTS,
} = types;

export default {
  [SAVE_ID](state, payload) {
    state.id = payload;
  },

  [SAVE_ROLE](state, payload) {
    state.role = payload;
  },

  [SAVE_NAME](state, payload) {
    state.name = payload;
  },

  [SAVE_EMAIL](state, payload) {
    state.email = payload;
  },

  [SAVE_TENANT](state, payload) {
    state.tenant = payload;
  },

  [SAVE_PICTURE](state, payload) {
    state.picture = payload;
  },

  [SAVE_PRODUCTS](state, payload) {
    state.products = payload;
  },
};
