import appConstants from '@/constants/app';

const { TENANT, THEME } = appConstants;

/**
 * @description Get tenant's logo
 * @param {String} tenant - the id of the tenant
 * @param {String} size - the size of the logo
 * @returns {String} logo path
 */
export function getLogo(tenant, size) {
  return `/img/logos/${tenant}-${size ? 'small' : 'large'}.png`;
}

/**
 * @description Gets the proper image for a product by tenant
 * @param {String} tenant - the id of the tenant
 * @param {String} product - the id of the product
 * @returns {String} image path
 */
export function getProductImage(tenant, product) {
  return `/img/photos/${tenant}-${product}.jpg`;
}

/**
 * @description Get the primary color of the theme
 * @param {String} tenant - the id of the tenant
 * @returns {String} primary color
 */
export function getPrimaryColor(tenant) {
  return THEME[tenant].primary;
}

/**
 * @description Init Vuetify theme
 * @returns {Object} Vuetify theme
 */
export function initTheme() {
  return {
    theme: {
      themes: {
        light: {
          primary: getPrimaryColor(TENANT.DEFAULT),
        },
      },
      // Vuetify variables available through css variables
      // https://vuetifyjs.com/en/features/theme/#custom-properties
      options: {
        customProperties: true,
      },
    },
  };
}
