/**
 * @description temp function to check whether promos section should be enabled, this should be provided by API
 * @returns {boolean} enabled state
 */
export default function isPromosEnabled(currentTenant) {
  const isFeatureEnabled = !!+process.env.VUE_APP_PROMO_ENABLED;
  const enabledTenants = process.env.VUE_APP_PROMO_ENABLED_TENANTS || '';
  const isTenantEnabled = enabledTenants.split(',').includes(currentTenant);
  return isFeatureEnabled && isTenantEnabled;
}
