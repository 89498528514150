import routerInstance from '@/router/configuration/router-instance';
import routerOnError from '@/router/configuration/router-on-error';
import routerGuards from '@/router/configuration/router-guards';

/**
 * Vue Router - Instance
 *
 * @returns {VueRouter}
 */
const router = routerInstance();

/**
 * Vue Router - Errors
 */
router.onError(routerOnError);

/**
 * Vue Router - Guards
 */
router.beforeEach(routerGuards);

export default router;
