<template>
  <div class="callback" />
</template>

<script>
import appConstants from '@/constants/app';

const home = '/';
const { AUTH } = appConstants;

export default {
  name: 'Callback',

  created() {
    this.redirectTo();
  },

  methods: {
    redirectTo() {
      this.$router.push({
        path: this.getStoredRedirect() || home,
      }, this.handleCompleteRedirect);
    },

    getStoredRedirect() {
      return localStorage.getItem(AUTH.REDIRECT_AFTER_LOGIN);
    },

    handleCompleteRedirect() {
      localStorage.removeItem(AUTH.REDIRECT_AFTER_LOGIN);
    },
  },
};
</script>
