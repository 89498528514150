import routesNames from '@/router/routes-names';
import Home from './home';
import Users from './users';
import Routes from './routes';
import Groups from './groups';
import Logs from './logs';

const { settings } = routesNames;

function Settings() {
  return import(/* webpackChunkName: "async-settings" */ '@/views/settings/settings.vue');
}

/**
 * @description Get settings
 * @returns {Array<Object>}
 */
export default [
  {
    component: Settings,
    path: `/${settings.main}`,
    children: [
      Home,
      Users,
      Routes,
      Groups,
      Logs,
    ],
  },
];
