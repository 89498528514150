import storeConstants from '@/constants/store';
import menu from '@/store/app/menu';
import search from '@/store/app/search';
import spinner from '@/store/app/spinner';
import snackbar from '@/store/app/snackbar';
import userInfo from '@/store/app/userInfo';
import masterData from '@/store/app/masterData';
import serverDate from '@/store/app/serverDate';
import confirmDialog from '@/store/app/confirmDialog';
import updateLoginInfo from '@/store/app/updateLoginInfo';
import features from '@/store/app/features';

const { APP } = storeConstants;

export default {
  [APP.MENU]: menu,
  [APP.SEARCH]: search,
  [APP.SPINNER]: spinner,
  [APP.SNACKBAR]: snackbar,
  [APP.USER_INFO]: userInfo,
  [APP.MASTERDATA]: masterData,
  [APP.SERVER_DATE]: serverDate,
  [APP.CONFIRM_DIALOG]: confirmDialog,
  [APP.UPDATE_LOGIN_INFO]: updateLoginInfo,
  [APP.FEATURES]: features,
};
