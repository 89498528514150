// Routes names for Chatbot product

export default {
  main: 'chatbot',
  dashboard: {
    main: 'dashboard',
    cumulio: 'dashboard-cumulio',
  },
  settings: {
    main: 'settings',
    opening: 'opening',
    queue: 'queue',
    handoverrestrictions: 'handoverrestrictions',
  },
};
