import Vue from 'vue';
import Vuex from 'vuex';
import app from '@/store/app';

Vue.use(Vuex);

/**
 * By default, only register the app modules
 * The Exchange and Upsells modules will be registered dynamically by Views.
 */
const store = new Vuex.Store({
  modules: app,
});

if (window.Cypress) {
  window.$store = store;
}

export default store;
