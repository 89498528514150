import Vue from 'vue';
import Vuex from 'vuex';
import Meta from 'vue-meta';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';

import VueCumulioDashboard from '@cumul.io/vue-cumulio-dashboard';
import { i18n } from '@/i18n';
import store from '@/store';
import router from '@/router';
import sentry from '@/plugins/sentry';
import vuetify from '@/plugins/vuetify';
import analytics from '@/plugins/analytics';
import App from '@/views/app/app.vue';
import Filters from '@/filters';
import Hotjar from 'vue-hotjar';

import Loading from '@/directives/loading/loading';

import '@/scss/index.scss';

// Vue plugins
Vue.use(Vuex);
Vue.use(Meta);
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueCumulioDashboard);
Vue.use(Filters);
Vue.use(Hotjar, {
  id: '2409360',
  isProduction: true,
});

Vue.directive('loading', Loading);

Vue.config.productionTip = false;

// Vendors plugins
sentry();
analytics(router);

// Vue instance
export default new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
