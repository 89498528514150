import routesNames from '@/router/routes-names';

const { upsells } = routesNames;

const Bids = () => import(/* webpackChunkName: "async-up-bids" */ '@/views/upsells/bids/bids.vue');

/**
 * @description Get upsells bids
 * @returns {Array<Object>}
 */
export default [{
  component: Bids,
  path: `/${upsells.main}/${upsells.bids.main}`,
  name: `${upsells.main}.${upsells.bids.main}`,
}];
