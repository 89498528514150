import actions from '@/store/app/alerts/actions';
import getters from '@/store/app/alerts/getters';
import mutations from '@/store/app/alerts/mutations';
import initialState from '@/store/app/alerts/initialState';

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
