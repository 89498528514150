import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { user } = endpoints;

/**
 * Get profile
 *
 * @async
 * @param {String} token - access token
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#user_get }
 */
async function getProfile({ token }) {
  try {
    const response = await axios.get(user, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error);
    throw error;
  }
}

/**
 * Update profile
 *
 * @async
 * @param {Object} payload.profileData - The profile data to update
 * @param {String} payload.token - access token
 * @param {Object} payload.userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#user_patch }
 */
async function updateProfile(payload) {
  const {
    profileData,
    token,
    userInfo,
  } = payload;

  try {
    const response = await axios.patch(user, profileData, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  profile: {
    getProfile,
    updateProfile,
  },
};
