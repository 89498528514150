import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import { isEnvDevelopment } from '@/utils/getEnvironments';

/**
 * @description Initialize Vue plugin for Google Analytics
 * @param {VueRouter} router
 * @link https://github.com/MatteoGabriele/vue-analytics
 */
export default function analytics(router) {
  if (!isEnvDevelopment) {
    Vue.use(VueAnalytics, {
      router,
      id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    });
  }
}
