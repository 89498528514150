import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { main, programs, expired } = endpoints.alerts;

/**
 * Sends a request to the API for getting the programs alerts
 *
 * @param {Object} payload - object with the days, token and user info
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#notifications_programs_expired__days__get}
 */
async function getPrograms(payload) {
  const {
    days,
    token,
    userInfo,
  } = payload;

  try {
    const response = await axios.get(`${main}/${programs}/${expired}/${days}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  alerts: {
    getPrograms,
  },
};
