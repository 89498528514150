import types from '@/store/app/confirmDialog/utils/types';

const {
  SHOW,
  HIDE,
  CANCELATION,
  CONFIRMATION,
} = types;

/**
 * @description Show confirm dialog
 * @param {Function} commit from Vuex
 * @param {Object} payload
 */
function show({ commit }, payload) {
  commit(SHOW, payload);
}

/**
 * @description Toggle the cancelation flag and hide the dialog
 * @param {Function} commit from Vuex
 */
function cancelation({ commit }) {
  commit(CANCELATION);
  commit(HIDE);
}

/**
 * @description Toggle the confirmation flag and hide the dialog
 * @param {Function} commit from Vuex
 * @param {Object} payload
 */
function confirmation({ commit }) {
  commit(CONFIRMATION);
  commit(HIDE);
}

export default {
  show,
  cancelation,
  confirmation,
};
