import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { initTheme } from '@/utils/theme';

const config = { ...initTheme() };

Vue.use(Vuetify);

export default new Vuetify(config);
