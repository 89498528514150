/**
 * @description Vuex mutations types for menu module
 */

const TOGGLE_MINI = 'TOGGLE_MINI';
const SET_CONTEXTUAL_TYPE = 'SET_CONTEXTUAL_TYPE';

const TOGGLE_PROFILE = 'TOGGLE_PROFILE';
const SET_PROFILE_TOP_POSITION = 'SET_PROFILE_TOP_POSITION';

export default {
  TOGGLE_MINI,
  SET_CONTEXTUAL_TYPE,

  TOGGLE_PROFILE,
  SET_PROFILE_TOP_POSITION,
};
