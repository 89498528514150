import appConstants from '@/constants/app';

const { ENV } = appConstants;
const env = process.env.VUE_APP_ENV;

export const isEnvStaging = (env === ENV.STAGING);
export const isEnvDevelopment = (env === ENV.DEVELOPMENT);
export const isEnvProduction = (env === ENV.PRODUCTION);

export const currentEnvironment = env;
