// Routes names for Upsells product

export default {
  main: 'upsells',
  dashboard: {
    main: 'dashboard',
  },
  programs: {
    main: 'programs',
    active: 'active',
    inactive: 'inactive',
  },
  bids: {
    main: 'bids',
  },
  program: {
    main: 'program',
    create: 'create',
    edit: 'edit',
    options: {
      description: 'description',
      flightTarget: 'flight-target',
      passengerProfiles: 'passenger-profiles',
      offers: 'offers',
      delivery: 'delivery',
      executionRules: 'execution-rules',
      conditions: 'conditions',
    },
  },
};
