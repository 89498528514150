import getDateFormatted from '@/store/utils/getDateFormatted';

/**
 * @description Transform API response before save it on Vuex
 * @param {Array<Object>} data - program list from API
 * @returns {Array<Object>} program list for Vuex
 */
export default function transformData(data) {
  return data.map((item) => {
    const {
      id,
      name,
      updatedAt,
      description,
    } = item;

    return {
      id,
      name,
      description,
      updatedAt: getDateFormatted(updatedAt),
    };
  });
}
