import Vue from 'vue';
import VueI18n from 'vue-i18n';
import defaultMessages from './en/defaultMessages';

Vue.use(VueI18n);

const defaultLocale = 'en';

/**
 * Internationalization plugin for Vue.js
 *
 * @link https://github.com/kazupon/vue-i18n
 * @returns VueI18n instance
 */
export const i18n = new VueI18n({
  locale: defaultLocale,
  messages: defaultMessages,
});

/**
 * Load app messages
 *
 * @returns {Promise}
 */
function loadAppMessages() {
  return import(/* webpackChunkName: "async-en-messages" */ '@/i18n/en/index');
}

/**
 * Request the app messages on the async way
 */
export async function requestAppMessages() {
  const messages = await loadAppMessages();
  i18n.setLocaleMessage(defaultLocale, messages.default);
}
