import axios from 'axios';
import isNil from 'lodash.isnil';
import endpoints from '@/middleware/api/utils/endpoints';
import isValidStatus from '@/middleware/api/utils/isValidStatus';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapProgramParametersToQuery from '@/middleware/api/utils/mapProgramParametersToQuery';

const { programs } = endpoints.exchange;

/**
 * @description Get all programs
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_get}
 */
async function getPrograms(payload) {
  const { token, userInfo } = payload;

  try {
    const response = await axios.get(`${programs.main}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get programs filtered by params
 * @param {Object} params - filters for requesting a specific list of programs
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_get}
 */
async function getProgramsFiltered(payload) {
  const { params, token, userInfo } = payload;

  try {
    const query = mapProgramParametersToQuery(params);
    const response = await axios.get(`${programs.main}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get programs by name
 * @param {Object} query - program name to search
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_names_get}
 */
async function getProgramsByName(payload) {
  const { query, token, userInfo } = payload;

  try {
    const response = await axios.get(`${programs.main}/${programs.names}?name=${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get a program by id
 * @param {String} id - program id
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs__id__get}
 */
async function getProgram(payload) {
  const { id, token, userInfo } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.programs.id'));
  }

  try {
    const response = await axios.get(`${programs.main}/${id}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Save a program
 * @param {Object} program - program data
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_post}
 */
async function saveProgram(payload) {
  const { program, token, userInfo } = payload;

  if (isNil(program)) {
    return Promise.reject(new Error('app.error.programs.program'));
  }

  try {
    const response = await axios.post(`${programs.main}`, program, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Import a program
 * @param {Object} program - program data
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_import_post}
 */
async function importProgram(payload) {
  const { program, token, userInfo } = payload;

  if (isNil(program)) {
    return Promise.reject(new Error('app.error.programs.program'));
  }

  try {
    const response = await axios.post(`${programs.main}/${programs.import}`, program, axiosConfiguration(token));
    return response;
  } catch (error) {
    const { data, status, statusText } = error.response;

    reportErrorToSentry(error, userInfo);

    throw JSON.stringify({
      data,
      status,
      statusText,
    });
  }
}

/**
 * @description Update a program
 * @param {String} id - program id
 * @param {Object} program - program data
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs__id__put}
 */
async function updateProgram(payload) {
  const {
    id,
    program,
    token,
    userInfo,
  } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.programs.id'));
  }

  try {
    const response = await axios.put(`${programs.main}/${id}`, program, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Clone a program
 * @param {String} id - id of the program to clone
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs__id__post}
 */
async function cloneProgram(payload) {
  const { id, token, userInfo } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.programs.id'));
  }

  try {
    const response = await axios.post(`${programs.main}/${id}`, {}, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Update the status of a program
 * @param {String} id - program id
 * @param {String} status - program status
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs__id__patch}
 */
async function updateStatus(payload) {
  const {
    id,
    status,
    token,
    userInfo,
  } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.programs.id'));
  }

  if (!isValidStatus(status)) {
    return Promise.reject(new Error('app.error.status'));
  }

  try {
    const response = await axios.patch(`${programs.main}/${id}`, { status }, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get program template
 * @param {String} id - id template
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link hhttps://documentation.caravelo.net/columbus-api/#seatresale_programs_programstemplates__id__get}
 */
async function getTemplate(payload) {
  const { id, token, userInfo } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.template'));
  }

  try {
    const response = await axios.get(`${programs.templates}/${id}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get a program file encoded on Base64
 * @param {String} id - program id
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs__id__json_get}
 */
async function getProgramFile(payload) {
  const { id, token, userInfo } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.programs.id'));
  }

  try {
    const response = await axios.get(`${programs.main}/${id}/${programs.json}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get a zip file with all programs encoded on Base64
 * @param {String} status - status for requesting a specific list of programs
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_programs_csv_get}
 */
async function getProgramsFiles(payload) {
  const { status, token, userInfo } = payload;

  if (isNil(status)) {
    return Promise.reject(new Error('app.error.status'));
  }

  try {
    const response = await axios.get(`${programs.main}/${programs.csv}?status=${status}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getPrograms,
  getProgramsByName,
  getProgramsFiltered,
  getProgram,
  saveProgram,
  importProgram,
  updateProgram,
  cloneProgram,
  getTemplate,
  updateStatus,
  getProgramFile,
  getProgramsFiles,
};
