import routesNames from '@/router/routes-names';

const { couponpass } = routesNames;

const Dashboard = () => import(/* webpackChunkName: "async-bot-dashboard" */ '@/views/couponpass/dashboard/dashboard.vue');
const CumulioDashboard = () => import('@/views/commons/dashboards/dashboard-cumulio.vue');

/**
 * @description Get couponpas dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: Dashboard,
  path: `/${couponpass.main}/${couponpass.dashboard.main}`,
  name: `${couponpass.main}.${couponpass.dashboard.main}`,
},
{
  component: CumulioDashboard,
  path: `/${couponpass.main}/${couponpass.dashboard.cumulio}`,
  name: `${couponpass.main}.${couponpass.dashboard.cumulio}`,
},
];
