/**
 * TODO: CLMBS-622 - in the future, update this when upsells have API endpoints
 */
function loadPrograms(status) {
  return import(/* webpackChunkName: "async-up-programs-mock" */ `@/store/upsells/programs/mocks/${status}.json`);
}

function loadProgram(id) {
  return import(/* webpackChunkName: "async-up-program-mock" */ `@/store/upsells/program/mocks/${id}.json`);
}

function loadTemplate(id) {
  return import(/* webpackChunkName: "async-up-template-mock" */ `@/store/upsells/program/mocks/${id}.json`);
}

/**
 * @description Get programs
 * @param {Object} params - parameters for requesting a specific list of programs
 * @returns {Promise}
 */
function getPrograms(params) {
  const status = params.status.toLowerCase();
  return loadPrograms(status);
}

/**
* @description Get program
* @param {String} id - program id
* @returns {Promise}
*/
function getProgram(id) {
  return loadProgram(id);
}

/**
* @description Save a program
* @param {Object} program - program data
* @returns {Promise}
*/
async function saveProgram() {
  const mockProgramNew = await loadProgram('mock-save');
  return Promise.resolve(mockProgramNew.default);
}

/**
* @description Update a program
* @param {String} id - program id
* @param {Object} program - program data
* @returns {Promise}
*/
async function updateProgram() {
  const mockProgramActive = await loadProgram('mock-active');
  return Promise.resolve(mockProgramActive.default);
}

/**
* @description Update the status of a program
* @param {String} id - program id
* @param {String} status - program status
* @returns {Promise}
*/
async function updateStatus(id, status) {
  if (status === 'ACTIVE') {
    const mockProgramActive = await loadProgram('mock-active');
    return Promise.resolve(mockProgramActive.default);
  }

  const mockProgramInactive = await loadProgram('mock-inactive');
  return Promise.resolve(mockProgramInactive.default);
}

/**
* @description Get template
* @param {String} id - template id
* @returns {Promise}
*/
function getTemplate(id) {
  return loadTemplate(id);
}

export default {
  getProgram,
  getPrograms,
  getTemplate,
  saveProgram,
  updateProgram,
  updateStatus,
};
