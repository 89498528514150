/**
 * @description Check if the request action has a successful response.
 * @param  {String} status - axios status response
 * @returns {Boolean}
 */
export function requestCompletedSuccessfully({ status }) {
  return status === 200;
}

/**
 * @description Check if create action has a successful response (status 201)
 * @param  {String} status - axios status response
 * @returns {Boolean}
 */
export function requestCreatedSuccessfully({ status }) {
  return status === 201;
}

/**
 * @description Check if the request has been accepted for processing (status 202)
 * @param  {String} status - axios status response
 * @returns {Boolean}
 */
export function requestAcceptedSuccessfully({ status }) {
  return status === 202;
}

/**
 * @sumary Check if request action response with no content (status 204)
 * @param  {Array<Object>} response - axios response
 * @returns {Boolean}
 */
export function requestNoContent({ status }) {
  return status === 204;
}

/**
 * @description Check if request action response with bad request (status 400)
 * @param {String} status - axios status response
 * @returns {Boolean}
 */
export function requestBadRequest({ status }) {
  return status === 400;
}

/**
 * @description Check if request action response with conflict (status 409)
 * @param {String} status - axios stauts response
 * @returns {Boolean}
 */
export function requestConflict({ status }) {
  return status === 409;
}
