/**
 * These are the default messages to display
 * when there was some error requesting the
 * basic configuration for bootstrapping the app
 *
 * @returns {object}
 */
export default {
  en: {
    app: {
      spinner: {
        loading: 'Loading Columbus...',
      },
      icons: {
        power: 'mdi-power',
        error: 'mdi-alert-circle-outline',
      },
      error: {
        userInfo: {
          request: 'We have found an error when trying to request the user info',
        },
        masterData: {
          request: 'We have found an error when trying to request the master data.',
        },
        serverDate: {
          request: 'We have found an error when trying to request the server date.',
        },
        error500: {
          title: 'Error 500',
          subheading: 'There was an unexpected error:',
          advice: 'Please, review your credentials and try again.',
          action: 'logout',
        },
      },
    },
  },
};
