<template>
  <v-navigation-drawer
    :mini-variant="mini"
    app
    dark
    permanent
    stateless
    hide-overlay
    class="primary"
    mini-variant-width="80"
  >
    <SideNavBarTop />

    <v-spacer />

    <template v-slot:append>
      <v-divider />

      <SideNavBarBottom />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import storeConstants from '@/constants/store';
import SideNavBarTop from '@/components/app/side-navbar/top/top.vue';
import SideNavBarBottom from '@/components/app/side-navbar/bottom/bottom.vue';

const { APP } = storeConstants;

export default {
  name: 'SideNavBar',

  components: {
    SideNavBarTop,
    SideNavBarBottom,
  },

  computed: {
    ...mapGetters({
      mini: `${APP.MENU}/sideNavBarMini`,
    }),
  },
};
</script>
<style lang="scss">
/**
 * XXX: There's a compiling issue with vuetify loader
 * https://github.com/vuetifyjs/vuetify/issues/7933
 * It will change the css order for production
 * we need to override some styles
 */
.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
    margin-left: 0;
    margin-right: 0;
}
</style>
