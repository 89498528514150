import routesNames from '@/router/routes-names';

const { settings } = routesNames;

function Logs() {
  return import(/* webpackChunkName: "async-settings-logs" */ '@/views/settings/logs/logs.vue');
}

function List() {
  return import(/* webpackChunkName: "async-settings-logs-list" */ '@/views/settings/logs/list/all/all.vue');
}

export default {
  path: `${settings.logs.main}`,
  component: Logs,
  children: [
    {
      path: '',
      component: List,
      name: `${settings.main}.${settings.logs.main}.${settings.logs.list}`,
    },
  ],
};
