<template>
  <v-container class="pa-3">
    <v-row>
      <v-col
        cols="12"
        md="6"
        offset-md="3"
        xl="4"
        offset-xl="4"
      >
        <v-sheet class="pa-4 elevation-6">
          <h3 class="text-h5 mb-3">
            <v-icon
              :color="color"
              medium
              left
            >
              {{ getIcon() }}
            </v-icon>
            {{ title }}
          </h3>
          <p class="text-body-1">
            {{ subheading }}
          </p>
          <slot name="extra" />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: 'error',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    subheading: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    getIcon() {
      return this.icon || this.$t('app.icons.error');
    },
  },
};
</script>
