import routesNames from '@/router/routes-names';

const { giftVoucher } = routesNames;

const VoucherRequests = () => import('@/views/gift-voucher/voucher-requests/voucher-requests.vue');

/**
 * @description Get gift voucher dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: VoucherRequests,
  path: `/${giftVoucher.main}/${giftVoucher.requests.main}`,
  name: `${giftVoucher.main}.${giftVoucher.requests.main}`,
}];
