import appConstants from '@/constants/app';

const { AUTH } = appConstants;

/**
 * @description Store the current path for use after login on callback component
 * @param {String} path
 */
export default async function saveRedictPath(path) {
  await localStorage.setItem(AUTH.REDIRECT_AFTER_LOGIN, path);
  return true;
}
