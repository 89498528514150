import isEmpty from 'lodash.isempty';
import * as Sentry from '@sentry/browser';

/**
 * Report the error to Sentry
 *
 * @param {Error} error - The error to report
 * @param {Object} userInfo - The user information: name, role and tenant
 */
export default function reportErrorToSentry(error, userInfo = {}) {
  if (!isEmpty(userInfo)) {
    const {
      name,
      role,
      tenant,
    } = userInfo;

    // Callback to set context information onto the scope.
    Sentry.configureScope((scope) => {
      // Updates user context information.
      scope.setUser({
        id: `user: ${name} - role: ${role} - tenant: ${tenant}`,
        userName: name,
        userRole: role,
        userTenant: tenant,
      });
    });
  }

  // Captures an exception event and sends it to Sentry.
  Sentry.captureException(error);
}
