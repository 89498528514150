/**
 * @description Create a query parameters for requesting a dashboard
 * @param {Object} payload - The payload
 * @param {String} payload.product - The product id (up, xc, bot)
 * @param {String} payload.tenant - The tenant code (D7, XJ, etc)
 * @example: product=up&tenant=D7
 * @returns {String} params queries for API
 */
export default function mapDashboardParametersToQuery(payload) {
  const query = [];
  const { product, tenant } = payload;

  if (product) {
    query.push(`product=${product}`);
  }

  if (tenant) {
    query.push(`tenant=${tenant}`);
  }

  return query.join('&');
}
