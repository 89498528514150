import URLSearchParams from '@ungap/url-search-params';

export default function mapReportsParametersToQuery(params) {
  let newParams;
  if (params.uri) {
    newParams = new URLSearchParams();
    params.uri.forEach((uri) => {
      newParams.append('uri', uri);
    });
  }
  return newParams || params;
}
