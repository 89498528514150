import routesNames from '@/router/routes-names';
import { getPath, getRedirect, getChildren } from '@/router/routes/exchange/utils/getProgramRoutes';

const { program } = routesNames.exchange;

function Edit() {
  return import(/* webpackChunkName: "async-xc-program-edit" */ '@/views/exchange/program/edit/edit.vue');
}

function Create() {
  return import(/* webpackChunkName: "async-xc-program-create" */ '@/views/exchange/program/create/create.vue');
}

/**
 * @description Get exchange program routes
 * @returns {Array<Object>}
 */
export default [
  {
    component: Edit,
    path: getPath(program.edit),
    children: getChildren(program.edit),
    redirect: getRedirect(program.edit),
  },
  {
    component: Create,
    path: getPath(program.create),
    children: getChildren(program.create),
    redirect: getRedirect(program.create),
  },
];
