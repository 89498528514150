import appConstants from '@/constants/app';

const { ROLES } = appConstants;

export default function initialState() {
  return {
    featuresInfo: {
      promos: {
        isEnabled: false,
        authorize: [ROLES.SUPER, ROLES.ADMIN],
      },
      dashboard: {
        authorize: [ROLES.SUPER, ROLES.ADMIN, ROLES.VIEWER],
      },
      report: {
        authorize: [ROLES.SUPER, ROLES.ADMIN, ROLES.VIEWER],
      },
    },
  };
}
