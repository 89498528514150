import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';
import store from '@/store';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;

const promosRoles = store.getters[`${APP.FEATURES}/getPromosRoles`];
const dashboardRoles = store.getters[`${APP.FEATURES}/getDashboardRoles`];

// App
const { PRODUCTS } = appConstants;
const { SUBSCRIPTIONS } = PRODUCTS;

// Routes
const { subscriptions } = routesNames;

// i18n
const product = `app.products.${SUBSCRIPTIONS}`;

/**
 * @description Get subscriptions data
 * @this Vue component
 * @returns {Object}
 */
export default function subscriptionstData() {
  const data = {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, SUBSCRIPTIONS),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description.${this.getTenant}`),
    sections: [],
  };

  const productButtons = [
    // Dashboard button
    {
      icon: this.$t('app.icons.dashboard'),
      title: this.$t(`${product}.sections.dashboard.title`),
      link: `/${subscriptions.main}/${subscriptions.dashboard.cumulio}`,
      enabled: true,
      authorize: dashboardRoles,
    },
    // User management button
    {
      icon: this.$t('app.icons.search'),
      title: this.$t(`${product}.sections.management.title`),
      link: `/${subscriptions.main}/${subscriptions.management.main}`,
      enabled: true,
    },
    // Promo buttons
    {
      icon: this.$t('app.icons.promos'),
      title: this.$t(`${product}.sections.promos.title`),
      link: `/${subscriptions.main}/${subscriptions.promos.main}`,
      enabled: this.isPromosEnabled,
      authorize: promosRoles,
    },
  ];

  data.sections = productButtons.filter((button) => {
    const hasAccess = button.authorize
      ? button.authorize.includes(this.getUserRole)
      : true;
    return button.enabled && hasAccess;
  });
  return data;
}
