<template>
  <p class="mt-3 text-subtitle-1 text-center">
    {{ message }}
  </p>
</template>

<script>
import appConstants from '@/constants/app';

const { EVENTS } = appConstants;

export default {
  name: 'Countdown',

  props: {
    /** Start time on miliseconds */
    startTime: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data() {
    return {
      message: '',
      timerId: null,
    };
  },

  mounted() {
    this.initTimer();
  },

  beforeDestroy() {
    /**
     * Must stop the timer before the component will be destroyed
     */
    clearInterval(this.timerId);
  },

  methods: {
    /**
     * @description Init the timer. UX best practices: https://ux.stackexchange.com/a/118003
    *  @emits {Event}
     */
    initTimer() {
      let minutes;
      let seconds;

      // Transform from miliseconds to seconds
      let timer = (this.startTime / 1000);

      // Each second, the timer will be updated
      this.timerId = setInterval(() => {
        // set a new sequence of the timer
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        // calculate timer
        minutes = (minutes < 10) ? `0${minutes}` : minutes;
        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        // update message
        this.message = this.$t('app.countdown', { minutes, seconds });

        // update timer
        timer -= 1;

        // emit a event when the countdown finish
        if (timer === 0) {
          this.$emit(EVENTS.COUNTDOWN_FINISH);
        }
      }, 1000);
    },
  },
};
</script>
