import isNil from 'lodash.isnil';
import appConstants from '@/constants/app';

const { OLD_SESSION_KEYS } = appConstants.AUTH;

export default async function removeOldSessionItems() {
  const {
    EXPIRES_AT,
    ID_TOKEN,
    ACCESS_TOKEN,
    ACTIVE_SESSION,
  } = OLD_SESSION_KEYS;

  const items = [
    ID_TOKEN,
    EXPIRES_AT,
    ACCESS_TOKEN,
    ACTIVE_SESSION,
  ];

  await items.forEach((item) => {
    if (!isNil(localStorage.getItem(item))) {
      localStorage.removeItem(item);
    }
  });

  return true;
}
