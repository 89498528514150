<template>
  <v-list-item three-line>
    <!--Avatar-->
    <v-list-item-avatar>
      <v-img :src="userPicture" />
    </v-list-item-avatar>

    <v-list-item-content>
      <!--Name-->
      <v-list-item-title>
        {{ userName }}
      </v-list-item-title>

      <!--Email-->
      <v-list-item-subtitle>
        {{ userEmail }}
      </v-list-item-subtitle>

      <!--Role-->
      <v-list-item-subtitle>
        {{ getRoleName() }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;

export default {
  name: 'UserInfo',

  computed: {
    ...mapGetters({
      userName: `${APP.USER_INFO}/getUserName`,
      userRole: `${APP.USER_INFO}/getUserRole`,
      userEmail: `${APP.USER_INFO}/getUserEmail`,
      userPicture: `${APP.USER_INFO}/getUserPicture`,
    }),
  },

  methods: {
    /**
     * Get the role name
     * @returns {String}
     */
    getRoleName() {
      return this.$t(`users.roles.${this.userRole}.text`);
    },
  },
};
</script>
