export default {
  PROGRAMS: {
    PAGINATION: {
      STORE_BY: {
        UPDATED_AT: 'updatedAt',
      },
      ORDER: {
        ASC: 'asc',
        DESC: 'desc',
      },
    },
  },
  PROGRAM: {
    TITLE: 'Program name',
    TYPE: 'SR_PROGRAM',
    TEMPLATE: {
      DEFAULT: 'template-1',
    },
    EVENTS: {
      ADD_OFFER: 'add-offer',
      ADD_REWARD: 'add-reward',
      ADD_PROFILE: 'add-profile',
      ADD_CRITERIA: 'add-criteria',
      ADD_DATE_RANGE: 'add-date-range',
      REMOVE_REWARD: 'remove-reward',
      REMOVE_PROFILE: 'remove-profile',
      REMOVE_CRITERIA: 'remove-criteria',
      RESET_CRITERIA: 'reset-criteria',
      COPY_REWARDS: 'copy-rewards',
      COPY_CRITERIA: 'copy-criteria',
      LAUNCH: 'launch-program',
      PAUSE: 'pause-program',
      SAVE: 'save-program',
      CANCEL: 'cancel-program',
      TABS_VALIDATE: 'tabs-validate',
      FIELDS_VALIDATE: 'fields-validate',
      UPDATE_PROFILE_NAME: 'update-profile-name',
    },
    STATUS: {
      CREATED: 'CREATED',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      CLOSED: 'CLOSED',
    },
    OPERATORS: {
      IN: 'IN',
      GT: 'GT',
      GEQT: 'GEQT',
      LEQT: 'LEQT',
      INCLUDE: 'INCLUDE',
      EXCLUDE: 'EXCLUDE',
      ANY_MATCH: 'ANY_MATCH',
    },
    ACTIONS: {
      EDIT: 'EDIT',
      CLONE: 'CLONE',
      PAUSE: 'PAUSE',
      ARCHIVE: 'ARCHIVE',
      DOWNLOAD: 'DOWNLOAD',
      CAMPAIGNS: 'CAMPAIGNS',
    },
    HEADER: {
      NAME: 'name',
    },
    DESCRIPTION: {
      NAME: 'description',
      TITLE: 'Description',
      ICON: 'mdi-file',
    },
    FLIGHT_TARGET: {
      NAME: 'flightTarget',
      TITLE: 'Flight target',
      ICON: 'mdi-airplane-takeoff',
      PROPERTIES: {
        CRITERIA: {
          GROUP: 'groups',
          ROUTES: 'routes',
          FLIGHT_CODES: 'flightCodes',
        },
        TARGET_MODE: {
          DEPARTURE_DATE: 'departureDate',
          DEPARTURE_RANGE: 'departureRange',
          TIME_TO_DEPART_RANGE: 'timeToDepartRange',
        },
        TIME_TO_DEPART_RANGE_UNIT: {
          DAYS: 'DAYS',
          HOURS: 'HOURS',
        },
        INVENTORY: {
          METRIC: {
            LOAD_FACTOR: 'LOAD_FACTOR',
            LID_LOAD_FACTOR: 'LID_LOAD_FACTOR',
            EXPECTED_LOAD_FACTOR: 'EXPECTED_LOAD_FACTOR',
          },
        },
        TARGET_CABIN: {
          ECONOMY: 'Y',
          BUSINESS: 'BUSINESS',
          PREMIUM_ECO: 'PREMIUM_ECO',
          ALL: 'all',
        },
        OPERATIONAL_WINDOW: {
          UNITS: {
            DAYS: 'DAYS',
            HOURS: 'HOURS',
          },
          TIME_POINTS: {
            DEFAULT: 2,
          },
        },
        DAYS_OF_WEEK: {
          MONDAY: 'MON',
          TUESDAY: 'TUE',
          WEDNESDAY: 'WED',
          THURSDAY: 'THU',
          FRIDAY: 'FRI',
          SATURDAY: 'SAT',
          SUNDAY: 'SUN',
        },
      },
    },
    ALTERNATIVE_FLIGHTS: {
      NAME: 'alternativeFlights',
      TITLE: 'Alternative flights',
      ICON: 'mdi-airplane-takeoff',
      PROPERTIES: {
        DEPARTURE_RANGE_UNIT: {
          DAYS: 'DAYS',
          HOURS: 'HOURS',
        },
        DEPARTURE_RANGE_OPTIONS: {
          BEFORE: -1,
          AFTER: 1,
        },
        INVENTORY: {
          METRIC: {
            LOAD_FACTOR: 'LOAD_FACTOR',
          },
        },
      },
    },
    PASSENGER_PROFILES: {
      NAME: 'passengerProfiles',
      TITLE: 'Passenger profiles',
      ICON: 'mdi-account-supervisor',
      FILTERS: {
        // Relational Operator
        PARTY_SIZE: 'PARTY_SIZE',
        JOURNEY_STAY: 'JOURNEY_STAY',

        // Exclude Operator
        PAX_TYPE: 'PAX_TYPE',
        RBD: 'RBD',
        SSR_CODES: 'SSR_CODES',
        FARE_BRAND: 'FARE_BRAND',
        POS: 'POS',

        // Special filters
        FFP_TIER_LEVEL: 'FFP_TIER_LEVEL',
        FFP_FORBIDDEN_TIER_LEVEL: 'FFP_FORBIDDEN_TIER_LEVEL',
      },
    },
    REWARDS: {
      NAME: 'rewards',
      TITLE: 'Rewards',
      ICON: 'mdi-wallet-giftcard',
      CATEGORIES: {
        DEFAULT: 'DEFAULT',
        ANCILLARY: 'ANCILLARY',
      },
      TYPES: {
        VOUCHER: {
          TYPE: 'VOUCHER',
          OPTIONS: {
            CUSTOM: 'custom',
            AMOUNT: 'amount',
          },
          CURRENCY: {
            EUR: 'EUR',
            USD: 'USD',
            ZAR: 'ZAR',
            BRL: 'BRL',
            AUD: 'AUD',
          },
        },
        MILES: {
          TYPE: 'MILES',
          OPTIONS: {
            CUSTOM: 'custom',
            AMOUNT: 'amount',
          },
        },
        CASH: {
          TYPE: 'CASH',
        },
        SERVICES: {
          TYPE: 'SERVICES',
        },
        ANCILLARY: {
          TYPE: 'ANCILLARY',
          OPTIONS: {
            FAST: 'FAST',
            UPGRADE: 'UPGRADE',
            EXTRA_BAG: 'EXTRA_BAG',
            LOUNGE_ACCESS: 'LOUNGE',
          },
        },
      },
    },
    OFFERS: {
      NAME: 'offers',
      TITLE: 'Offers',
      ICON: 'mdi-cash-multiple',
      OPTIONS: {
        BIZ_FIXED_CONFIRMED: 'offerBizFixedConfirmed',
        BIZ_FIXED_STANDBY: 'offerBizFixedStandBy',
        PREMIUM_FIXED_CONFIRMED: 'offerPremiumFixedConfirmed',
        PREMIUM_FIXED_STANDBY: 'offerPremiumFixedStandBy',
        BIZ_VARIABLE_STANDBY: 'offerBizVariableStandBy',
        PREMIUM_VARIABLE_STANDBY: 'offerPremiumVariableStandBy',
      },
      PROPERTIES: {
        CABIN: {
          BIZ: 'biz',
          PREMIUM: 'premium',
        },
        PRICE: {
          FIXED: 'fixed',
          VARIABLE: 'variable',
        },
        EXECUTION: {
          CONFIRMED: 'confirmed',
          STANDBY: 'standby',
        },
        EXTRAS: {
          AVOID_CANNIBALIZATION: 'AVOID_CANNIBALIZATION',
          ADJUSTMENT_LOAD_FACTOR: 'ADJUSTMENT_LOAD_FACTOR',
        },
      },
    },
    DELIVERY: {
      NAME: 'delivery',
      TITLE: 'Delivery',
      ICON: 'mdi-mailbox',
      PROPERTIES: {
        OFFER_INVITE: {
          EMAIL: 'email',
          SMS: 'sms',
          IBE_MMB: 'ibemmb',
        },
        SERVICE_AVAILABILITY: {
          DAYS: 'days',
          HOURS: 'hours',
        },
        CONTENT: {
          TEMPLATE1: 'template1',
          TEMPLATE2: 'template2',
          DEFAULT: 'default',
        },
        /**
         * TODO: this value come from exchange settings:
         * init/targetFlightsBookingCurve
         * save/targetFlightsBookingCurve
        */
        GOAL: {
          LOAD_FACTOR: 'LOAD_FACTOR',
          SEATS_RELEASED: 'SEATS_RELEASED',
        },
      },
    },
    EXECUTION_RULES: {
      NAME: 'executionRules',
      TITLE: 'Execution rules',
      ICON: 'mdi-play-circle-outline',
      PROPERTIES: {
        CLEARANCE: {
          OPTIMAL_ALLOCATION: 'optimalAllocation',
          TOTAL_AMOUNT: 'totalAmount',
          VALUE_INDEX: 'valueIndex',
          RBD: 'rbd',
          DATE_REQUEST: 'dateRequest',
          PAX_PROFILE: 'paxProfile',
        },
      },
    },
    SETTINGS: {
      NAME: 'settings',
      TITLE: 'Settings',
      ICON: 'mdi-settings-outline',
      PROPERTIES: {
        PROGRAM_TYPE: {
          MOVE: 'move',
          MOVE_CANCEL: 'moveCancel',
          CANCEL: 'cancel',
        },
        OFFER_DELIVERY: {
          AFTER_DELIVERY: 'afterDelivery',
          BEFORE_OPERATION_WINDOW: 'beforeOperationWindow',
        },
        OFFER_DELIVERY_UNIT: {
          DAYS: 'days',
          HOURS: 'hours',
        },
        GOAL: {
          LOAD_FACTOR: 'LOAD_FACTOR',
          SEATS_RELEASED: 'SEATS_RELEASED',
          LID_LOAD_FACTOR: 'LID_LOAD_FACTOR',
        },
        BATCH_SIZE: {
          PASSENGERS: 'PASSENGERS',
        },
        BATCH_RELEASE_EVERY_UNIT: {
          DAYS: 'DAYS',
          HOURS: 'HOURS',
        },
        RUNTIME_RULES: {
          EXCHANGE: 'EXCHANGE',
          DENIED_BOARDING: 'DENIED_BOARDING',
          STANDBY_AT_GATE: 'STANDBY_AT_GATE',
        },
        DISTRIBUTION_TYPE: {
          ALL: 'ALL',
          BY_BATCHES: 'BY_BATCHES',
        },
      },
    },
    CONDITIONS: {
      NAME: 'conditions',
      TITLE: 'Conditions',
      ICON: 'mdi-check-all',
    },
    VALIDATIONS: {
      IMPORT: {
        FILE: {
          TYPE: 'application/json',
          MAX_SIZE_ON_BYTE: 2000000,
        },
      },
    },
  },
};
