/**
 * @description Get default query: page and per page
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Array} Filter by page or per page
 */
function queryPageAndPerPage(page, perPage) {
  const query = [];

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  return query;
}

/**
 * @description Get query by status and product
 * @param {String} status
 * @param {String} product
 * @return {Array} Filter by status or product
 */
function queryStatusAndProduct(status, product) {
  const query = [];

  if (status) {
    query.push(`status=${status}`);
  }

  if (product) {
    query.push(`bidType=${product.toUpperCase()}`);
  }

  return query;
}

/**
 * @description Get query by carrier code
 * @param {String} carrierCode  code of carrier to filter
 * @return {Array} Filters by carrier code
 */
function queryCarrierCode(carrierCode) {
  const query = [];

  if (carrierCode) {
    query.push(`flightCarrierCode=${carrierCode}`);
  }

  return query;
}

/**
 * @description Create a query parameters for requesting a list of bids
 * @param {Object} params - parameters for requesting a specific list of bids
 * @example: page=1&per_page=10
 * @example: status=ACCEPTED
 * @returns {String} params queries for API
 */
export default function mapBidParametersToQuery(params) {
  const {
    page,
    status,
    product,
    perPage,
    carrierCode,
  } = params;

  const query = [
    ...queryPageAndPerPage(page, perPage),
    ...queryStatusAndProduct(status, product),
    ...queryCarrierCode(carrierCode),
  ];

  return query.join('&');
}
