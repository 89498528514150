import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';

// App
const { PRODUCTS } = appConstants;
const { EXCHANGE } = PRODUCTS;

// Routes
const { exchange } = routesNames;

// i18n
const product = `app.products.${EXCHANGE}`;

/**
 * @description Exchange data
 * @this Vue component
 * @returns {Object}
 */
export default function exchangeData() {
  return {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, EXCHANGE),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description`),
    sections: [
      {
        icon: this.$t('app.icons.dashboard'),
        title: this.$t(`${product}.sections.dashboard.title`),
        link: `/${exchange.main}/${exchange.dashboard.main}`,
      },
      {
        icon: this.$t('app.icons.list'),
        title: this.$t(`${product}.sections.programs.title`),
        link: `/${exchange.main}/${exchange.programs.main}`,
      },
      {
        icon: this.$t('app.icons.campaign'),
        title: this.$t(`${product}.sections.campaigns.title`),
        link: `/${exchange.main}/${exchange.campaigns.main}`,
      },
    ],
  };
}
