import api from '@/middleware/api';
import appConstants from '@/constants/app';
import { requestCompletedSuccessfully } from '@/store/utils/request';
import types from '@/store/app/masterData/utils/types';
import getApiConfiguration from '@/store/utils/getApiConfiguration';

const { SAVE } = types;
const { STATUS } = appConstants;
const { OK } = STATUS;

/**
 * @description Request master data
 * @param {Function} commit from Vuex
 * @param {Object} rootGetters from Vuex
 * @returns {Promise}
 */
async function requestMasterData({ commit, rootGetters }) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    const { data, status } = await api.app.getMasterData({ ...apiConfiguration });

    commit(SAVE, data);

    return { status };
  } catch (error) {
    throw new Error('app.error.masterData.request');
  }
}

/**
 * @description makes a request to update the routes information in the server
 * @param {Function} commit from Vuex
 * @param {Object} rootGetters from Vuex
 * @param {Array<String>} list - list of new routes
 */
async function requestUpdateRoutes({ commit, rootGetters }, list) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    const response = await api.masterdata.updateRoutes({
      list,
      ...apiConfiguration,
    });

    if (requestCompletedSuccessfully(response)) {
      // if the request is OK, must update the master data
      // for show it correctly next time the user goes to the routes tab, in the settings section
      commit(SAVE, response.data);
      return OK;
    }

    throw Error('masterdata.error.routes.update');
  } catch (error) {
    throw Error('masterdata.error.routes.update');
  }
}

/**
 * @description makes a request to update the routes groups information in the server
 * @param {Function} commit from Vuex
 * @param {Object} rootGetters from Vuex
 * @param {Array<Object>} list - list of new values groups
 */
async function requestUpdateRoutesGroups({ commit, rootGetters }, list) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    const response = await api.masterdata.updateGroups({
      list,
      ...apiConfiguration,
    });

    if (requestCompletedSuccessfully(response)) {
      // if the request is OK, must update the master data
      // for show it correctly next time the user goes to the routes groups tab, in the settings section
      commit(SAVE, response.data);
      return OK;
    }

    throw Error('masterdata.error.groups.update');
  } catch (error) {
    throw Error('masterdata.error.groups.update');
  }
}

export default {
  requestMasterData,
  requestUpdateRoutes,
  requestUpdateRoutesGroups,
};
