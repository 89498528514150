<template>
  <div class="footer">
    <small>
      &copy; {{ getInfo() }}
    </small>
    <br>
    <small>
      {{ $t('app.footer.release') }}
      {{ getReleaseDate() }}
    </small>
  </div>
</template>

<script>
import isNil from 'lodash.isnil';
import getReleaseDate from '@/components/app/footer/utils/getReleaseDate';
import { isEnvDevelopment, isEnvStaging } from '@/utils/getEnvironments';

export default {
  name: 'Footer',

  methods: {
    /**
     * Get info
     *
     * @returns {String}
     */
    getInfo() {
      const info = this.$t('app.footer.info');
      const environment = process.env.VUE_APP_ENV;

      if (isEnvStaging || isEnvDevelopment) {
        return `${info} ${environment}`;
      }

      return info;
    },

    /**
     * Get release date
     *
     * @returns {String}
     */
    getReleaseDate() {
      const releaseDate = process.env.VUE_APP_RELEASE;
      return isNil(releaseDate) ? 'dev' : getReleaseDate(releaseDate);
    },
  },
};
</script>

<style lang="scss">
  .footer {
    bottom: 5px;
    left: 20px;
    position: absolute;
    width: 100%;
  }
</style>
