import routesNames from '@/router/routes-names';

const { exchange } = routesNames;
const { program } = exchange;

/**
 * @description Get path of program
 * @param type - type of program
 * @returns {String}
 */
export function getPath(type) {
  if (type === program.edit) {
    return `/${exchange.main}/${program.main}/${type}/:id`;
  }

  return `/${exchange.main}/${program.main}/${type}`;
}

/**
 * @description Get redirect of program
 * @param type - type of program
 * @returns {Object}
 */
export function getRedirect(type) {
  return {
    name: `${exchange.main}.${program.main}.${type}.${program.options.description}`,
  };
}

/**
 * @description List of children routes of program
 * @param type - type of program
 * @returns {Array<Object>}
*/
export function getChildren(type) {
  const options = Object.values(program.options);

  return options.map((option) => ({
    path: option,
    name: `${exchange.main}.${program.main}.${type}.${option}`,
  }));
}
