import routesNames from '@/router/routes-names';

const { settings } = routesNames;

/**
 * Get users management data
 *
 * @this Vue component
 * @returns {Object}
 */
function getUsersManagement() {
  return {
    icon: this.$t('app.icons.users'),
    title: this.$t('settings.home.users.title'),
    link: `/${settings.main}/${settings.users.main}`,
  };
}

/**
 * Get routes data
 *
 * @this Vue component
 * @returns {Object}
 */
function getRoutesData() {
  return {
    icon: this.$t('app.icons.flightTakeoff'),
    title: this.$t('settings.home.routes.title'),
    link: `/${settings.main}/${settings.routes.main}`,
  };
}

/**
 * Get logs data
 *
 * @this Vue component
 * @returns {Object}
 */
function getLogsData() {
  return {
    icon: this.$t('app.icons.logs'),
    title: this.$t('settings.home.logs.title'),
    link: `/${settings.main}/${settings.logs.main}`,
  };
}

/**
 * Get groups data
 *
 * @this Vue component
 * @returns {Object}
 */
function getGroupsData() {
  return {
    icon: this.$t('app.icons.groupWork'),
    title: this.$t('settings.home.groups.title'),
    link: `/${settings.main}/${settings.groups.main}`,
  };
}

/**
 * Check if the user has access to the users management
 *
 * @this Vue component
 * @returns {Boolean}
 */
function checkUsersManagement() {
  return this.hasUserAdminPrivileges;
}
/**
 * Check if the user has access to show logs
 *
 * @this Vue component
 * @returns {Boolean}
 */
function checkLogs() {
  return this.hasUserAdminPrivileges;
}

/**
 * Check if the user has access to master data
 *
 * @this Vue component
 * @returns {Boolean}
 */
function checkMasterData() {
  return this.hasUserAdminPrivileges && this.hasAccessToExchange;
}

/**
 * Get settings sections
 *
 * @this Vue component
 * @returns {Array}
 */
function getSections() {
  const sections = [];
  const showMasterData = checkMasterData.call(this);
  const showUsersManagement = checkUsersManagement.call(this);
  const showLogsData = checkLogs.call(this);

  if (showUsersManagement) {
    const usersManagement = getUsersManagement.call(this);
    sections.push(usersManagement);
  }
  if (showLogsData) {
    const logsData = getLogsData.call(this);
    sections.push(logsData);
  }

  if (showMasterData) {
    const routesData = getRoutesData.call(this);
    const groupsData = getGroupsData.call(this);

    sections.push(routesData);
    sections.push(groupsData);
  }

  return sections;
}

/**
 * Get setting data
 *
 * @this Vue component
 * @returns {Object}
 */
export default function settingsData() {
  return {
    icon: this.$t('app.icons.settings'),
    title: this.$t('settings.home.title'),
    sections: getSections.call(this),
  };
}
