import isNil from 'lodash.isnil';
import offerConstants from '@/constants/offer';

const { OFFERS } = offerConstants;
const { VOUCHER } = OFFERS.REWARDS.TYPES;

/**
 * @description Map offer parameters to query
 * @param {String} type - reward type
 * @param {Number} paxId - passenger id
 * @param {Boolean} sendEmailValue - if must send email or not (optional)
 * @returns {String}
 */
export default function mapOfferParametersToQuery(type, paxId, sendEmailValue) {
  // if no value is received for 'sendEmail' parameter, it must be defined according
  // to if the reward is for a voucher or not, otherwise, use the received value.
  const sendEmail = isNil(sendEmailValue) ? (type === VOUCHER) || false : sendEmailValue;
  return `type=${type}&paxId=${paxId}&sendEmail=${sendEmail}`;
}
