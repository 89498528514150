// Routes names for subscriptions product
export default {
  main: 'subscriptions',
  dashboard: {
    main: 'dashboard',
    cumulio: 'dashboard-cumulio',
  },
  management: {
    main: 'management',
  },
  promos: {
    main: 'promos',
    create: 'create',
    edit: 'edit',
    view: 'view',
  },
  subscriber: {
    main: 'subscriber-details',
  },
};
