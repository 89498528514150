import actions from '@/store/app/serverDate/actions';
import getters from '@/store/app/serverDate/getters';
import mutations from '@/store/app/serverDate/mutations';
import initialState from '@/store/app/serverDate/initialState';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
