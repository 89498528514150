import orderBy from 'lodash.orderby';
import programConstants from '@/constants/program';
import existsStation from '@/store/app/masterData/utils/existsStation';

const { PROGRAM } = programConstants;

const {
  ROUTES,
  FLIGHT_CODES,
} = PROGRAM.FLIGHT_TARGET.PROPERTIES.CRITERIA;

const getRBD = (state) => state.data.fares.rbd;

const getRewards = (state) => state.data.sr.rewards;

const getCabins = (state) => state.data.cabins;

const getPointOfSales = (state) => state.data.pointOfSales;

const getSpecialServicesRequested = (state) => state.data.ssrs;

const getCurrencies = (state) => state.data.currencies.map((item) => item.currencyCode);

const getDefaultCurrency = (state) => state.data.defaultCurrency;

const getBrandedFares = (state) => state.data.fares.brandedFares;

const getPassengersTypes = (state) => state.data.passengers.types;

const getFrequentFlyerProgram = (state) => state.data.passengers.ffp;

/**
 * @description Get the groups
 * @param {Object} state - master data from Vuex
 * @returns {Array.<Object>} groups with id, name and routes
 */
const getGroups = (state) => state.data.flights.groups;

/**
 * @description Get the routes
 * @param {Object} state - master data from Vuex
 * @returns {Array.<Object>} routes for v-autocomplete
 */
const getFlightsRoutes = (state) => {
  const { routes } = state.data.flights;
  return orderBy(routes, (route) => route);
};

/**
 * @description Get the flights codes
 * @param {Object} state - master data from Vuex
 * @returns {Array} flight codes for v-autocomplete
 */
const getFlightCodes = (state) => {
  const { flightCodes } = state.data.flights;
  const items = flightCodes.map((item) => `${item.carrierCode}-${item.flightNumber}`);

  return orderBy(items, (item) => item);
};

/**
 * @description Get only the groups names
 * @param {Object} state - master data from Vuex
 * @returns {Array} group names for v-autocomplete
 */
const getFlightsGroups = (state) => {
  const { groups } = state.data.flights;
  const names = groups.map((group) => group.name);
  return orderBy(names, (name) => name);
};

/**
 * @description Get the flights (routes, groups or flight codes) by criteria
 * @param {Object} state - master data from Vuex
 * @param {String} criteria - program criteria
 * @returns {Array} flights for v-autocomplete
 */
const getFlightsByCriteria = (state) => (criteria) => {
  if (criteria === ROUTES) {
    return getFlightsRoutes(state);
  }

  if (criteria === FLIGHT_CODES) {
    return getFlightCodes(state);
  }

  return getFlightsGroups(state);
};

/**
 * @description Default exclusions for Upgrade
 * @param {Object} state - master data of Vuex
 */
const getDefaultExclusionsForUpgrade = () => {
  // TODO: For AM demo
  // Delete when Upsells will upload to PRO
  // Sandbox couchbase does not update data
  const data = [
    {
      bookingStatus: [
        'CONFIRMED',
      ],
      description: 'Only confirmed bookings',
    },
    {
      ticketed: true,
      description: 'Only ticketed',
    },
  ];

  return data.map((item) => item.description);
};

/**
 * @description Get list of stations joined by departure and arrival
 * @param {Object} state - master data of Vuex
 * @returns {Object} list of stations
 */
const getStations = (state) => {
  const initialState = {
    all: [],
    departure: [],
    arrival: [],
  };

  /**
   * Get the routes
   * Example: [
   *   "CPT-DUR",
   *   "CPT-JNB",
   *   "DUR-CPT",
   *   "DUR-JNB",
   *   "JNB-CPT",
   *   "JNB-DUR"
   *  ]
   */
  const { routes } = state.data.flights;

  /**
   * First map the routes
   * and then organize them by: all, departure and arrival
   */
  const result = routes
    /**
    * Map the routes
    * Example: [
    *   ["CPT", "DUR"],
    *   ["CPT", "JNB"],
    *   ["DUR", "CPT"],
    *   ["DUR", "JNB"],
    *   ["JNB", "CPT"],
    *   ["JNB", "DUR"]
    * ]
    */
    .map((route) => route.split('-'))
    /**
    *  Organize the routes
    *  Example: {
    *   all: ["AAA", "BBB", "CCC", 'DDD', 'EEE'],
    *   arrival: ["AAA", "BBB", "CCC"],
    *   departure: ["AAA", "DDD", "EEE"]
    *  }
    */
    .reduce((stations, route) => {
      const [departure, arrival] = route;

      // All: departure
      if (!existsStation(stations.all, departure)) {
        stations.all.push(departure);
      }

      // All: arrival
      if (!existsStation(stations.all, arrival)) {
        stations.all.push(arrival);
      }

      // Departure
      if (!existsStation(stations.departure, departure)) {
        stations.departure.push(departure);
      }

      // Arrival
      if (!existsStation(stations.arrival, arrival)) {
        stations.arrival.push(arrival);
      }

      return stations;
    }, initialState);

  // Sort the stations by name
  return {
    all: result.all.sort(),
    departure: result.departure.sort(),
    arrival: result.arrival.sort(),
  };
};

export default {
  getRBD,
  getCabins,
  getGroups,
  getRewards,
  getPointOfSales,
  getBrandedFares,
  getCurrencies,
  getDefaultCurrency,
  getStations,
  getFlightCodes,
  getFlightsGroups,
  getFlightsRoutes,
  getFlightsByCriteria,
  getPassengersTypes,
  getFrequentFlyerProgram,
  getSpecialServicesRequested,
  getDefaultExclusionsForUpgrade,
};
