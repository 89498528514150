import actions from '@/store/app/confirmDialog/actions';
import mutations from '@/store/app/confirmDialog/mutations';
import getters from '@/store/app/confirmDialog/getters';
import initialState from '@/store/app/confirmDialog/initialState';

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: initialState(),
};
