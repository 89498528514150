import appConstants from '@/constants/app';

const { TENANT } = appConstants;

/**
 * @description Check if current tenant is a demo tenant
 * @param {String} currentTenant
 * @returns {Boolean}
 */
export default function isDemoTenant(currentTenant) {
  return (
    currentTenant === TENANT.CVO
    || currentTenant === TENANT.AM
  );
}
