/**
 * @description Vuex mutations types for confirm dialog module
 */

const SHOW = 'show';
const HIDE = 'hide';
const CONFIRMATION = 'confirmation';
const CANCELATION = 'cancelation';

export default {
  SHOW,
  HIDE,
  CONFIRMATION,
  CANCELATION,
};
