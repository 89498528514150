/**
 * @description Get query by stations
 * @param {String} stations - departure station and arrival station
 * @return {Array} Filter by departure station or arrival station
 */
function queryByStations(stations) {
  const query = [];

  if (stations && stations.departure) {
    query.push(`departureStation=${stations.departure}`);
  }

  if (stations && stations.arrival) {
    query.push(`arrivalStation=${stations.arrival}`);
  }

  return query;
}

/**
 * @description Get query by departure date
 * @param {Object} departureDate - start date and end date
 * @return {Array} Filters by departure start date or end date
 */
function queryByDepartureDate(departureDate) {
  const query = [];

  if (departureDate && departureDate.start) {
    query.push(`departureDateFrom=${departureDate.start}`);
  }

  if (departureDate && departureDate.end) {
    query.push(`departureDateTo=${departureDate.end}`);
  }

  return query;
}

/**
 * @description Get query by flight number
 * @param {String} flightNumber flight number to filter
 * @return {Array} Filters by flight number
 */
function queryFlightNumber(flightNumber) {
  const query = [];

  if (flightNumber) {
    query.push(`flightNumber=${flightNumber}`);
  }

  return query;
}

/**
 * @description Create a query parameters for requesting a list of campaigns
 * @param {Object} params - parameters for requesting a specific list of campaigns
 * @example: page=1&per_page=10&sort=updatedAt&order=desc
 * @returns {String} params queries for API
 */
export default function mapSearchFilterParametersToQuery(params) {
  const {
    stations,
    departureDate,
    flightNumber,
  } = params;

  const query = [
    ...queryByStations(stations),
    ...queryByDepartureDate(departureDate),
    ...queryFlightNumber(flightNumber),
  ];

  return query.join('&');
}
