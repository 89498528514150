/**
 * @description Vuex mutations types for user info module
 */

const SAVE_ID = 'SAVE_ID';
const SAVE_ROLE = 'SAVE_ROLE';
const SAVE_NAME = 'SAVE_NAME';
const SAVE_EMAIL = 'SAVE_EMAIL';
const SAVE_TENANT = 'SAVE_TENANT';
const SAVE_PICTURE = 'SAVE_PICTURE';
const SAVE_PRODUCTS = 'SAVE_PRODUCTS';
export default {
  SAVE_ID,
  SAVE_ROLE,
  SAVE_NAME,
  SAVE_EMAIL,
  SAVE_TENANT,
  SAVE_PICTURE,
  SAVE_PRODUCTS,
};
