import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapLogParametersToQuery from '@/middleware/api/utils/mapLogParametersToQuery';

const { logs } = endpoints;

/**
 * Get logs
 *
 * @async
 * @param {Object} payload
 * @param {String} payload.token - The access token
 * @param {Object} payload.params - The filters for requesting a specific list of logs
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#logs_get}
*/
async function getLogs(payload) {
  const { params, token } = payload;

  try {
    const query = mapLogParametersToQuery(params);
    const response = await axios.get(`${logs.main}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error);
    throw error;
  }
}

export default {
  logs: {
    getLogs,
  },
};
