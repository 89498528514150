import actions from '@/store/app/search/actions';
import getters from '@/store/app/search/getters';
import mutations from '@/store/app/search/mutations';
import initialState from '@/store/app/search/initialState';

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
