<template>
  <span />
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/middleware/auth';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;

export default {
  created() {
    this.logoutUser();
  },

  methods: {
    ...mapActions({
      showSpinner: `${APP.SPINNER}/show`,
    }),

    /**
     * Logout user
     */
    logoutUser() {
      // Show message
      const message = this.$t('app.spinner.logout');
      this.showSpinner(message);

      // Logout user
      auth.then((client) => client.logout());
    },
  },
};
</script>
