import routesNames from '@/router/routes-names';

const { chatbot } = routesNames;

const Dashboard = () => import(/* webpackChunkName: "async-bot-dashboard" */ '@/views/chatbot/dashboard/dashboard.vue');
const CumulioDashboard = () => import('@/views/commons/dashboards/dashboard-cumulio.vue');

/**
 * @description Get chatbot dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: Dashboard,
  path: `/${chatbot.main}/${chatbot.dashboard.main}`,
  name: `${chatbot.main}.${chatbot.dashboard.main}`,
},
{
  component: CumulioDashboard,
  path: `/${chatbot.main}/${chatbot.dashboard.cumulio}`,
  name: `${chatbot.main}.${chatbot.dashboard.cumulio}`,
},
];
