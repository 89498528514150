<template>
  <v-dialog
    v-if="show"
    v-model="show"
    :persistent="true"
    :max-width="maxWidth"
  >
    <v-card class="pa-2">
      <!--Title-->
      <v-card-title class="text-h5">
        <v-icon
          left
          color="primary"
        >
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-card-title>

      <!--Text-->
      <v-card-text>
        <template v-if="dialogType">
          <v-alert
            colored-border
            dense
            icon="mdi-alert-circle"
            :type="dialogType"
            border="left"
          >
            <p
              class="ma-0"
              v-html="text"
            />
          </v-alert>
        </template>
        <template v-else>
          {{ text }}
        </template>

        <Countdown
          v-if="countdownShow"
          :start-time="countdownStartTime"
          @countdown-finish="handleCancelation"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <!--Button: cancelation-->
        <v-btn
          id="clmbs-cancel"
          small
          text
          color="primary"
          @click="handleCancelation"
        >
          {{ btnCancelation }}
        </v-btn>

        <!--Button: confirmation-->
        <v-btn
          id="clmbs-ok"
          small
          :outlined="!dialogType"
          :color="dialogType || 'primary'"
          @click="handleConfirm"
        >
          {{ btnConfirmation }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import storeConstants from '@/constants/store';
import Countdown from '@/components/app/countdown/countdown.vue';

const { APP } = storeConstants;

export default {
  name: 'ConfirmDialog',

  components: {
    Countdown,
  },

  data() {
    return {
      displayCountdown: false,
    };
  },

  computed: {
    ...mapState([APP.CONFIRM_DIALOG]),

    /**
     * @description Display the id
     * @returns {String}
     */
    id() {
      return this.appConfirmDialog.id;
    },

    /**
     * @description Display the icon
     * @returns {String}
     */
    icon() {
      return this.appConfirmDialog.icon
        ? this.appConfirmDialog.icon
        : this.$t('app.icons.contact_support');
    },

    /**
     * @description Display the dialog box
     * @returns {Boolean}
     */
    show() {
      return this.appConfirmDialog.show;
    },

    /**
     * @description The title of the dialog box
     * @returns {String}
     */
    title() {
      return this.appConfirmDialog.title;
    },

    /**
     * @description The text of the dialog box
     * @returns {String}
     */
    text() {
      return this.appConfirmDialog.text;
    },

    /**
     * @description Check if show the countdown
     * @returns {Boolean}
     */
    countdownShow() {
      return this.appConfirmDialog.countdown.show;
    },

    /**
     * @description Countdown start time
     * @returns {Number}
     */
    countdownStartTime() {
      return this.appConfirmDialog.countdown.startTime;
    },

    /**
     * @description The text for the cancelation button
     * @returns {String}
     */
    btnCancelation() {
      return this.appConfirmDialog.buttons.cancelation || this.$t('app.modal.cancel');
    },

    /**
     * @description The text for the confirmation button
     * @returns {String}
     */
    btnConfirmation() {
      return this.appConfirmDialog.buttons.confirmation || this.$t('app.modal.ok');
    },

    /**
     * @description Callback to be excecuted on confirm to make things strightforward
     */
    confirmCallback() {
      return this.appConfirmDialog.confirmCallback || null;
    },

    /**
     * @description Callback to be excecuted on cancel to make things strightforward
     */
    cancelCallback() {
      return this.appConfirmDialog.cancelCallback || null;
    },

    /**
     * @description Used to show the content in an alert (warning, error, success)
     */
    dialogType() {
      return this.appConfirmDialog.dialogType || null;
    },

    maxWidth() {
      return this.appConfirmDialog.maxWidth || 290;
    },
  },

  methods: {
    ...mapActions({
      cancelation: `${APP.CONFIRM_DIALOG}/cancelation`,
      confirmation: `${APP.CONFIRM_DIALOG}/confirmation`,
    }),

    /**
     * @description Handle the action when the user clicks on the 'cancelation' button
     */
    async handleCancelation() {
      await this.cancelCallback?.();
      this.cancelation();
    },

    /**
     * @description Handle the action when the user clicks on the 'confirmation' button
     */
    async handleConfirm() {
      await this.confirmCallback?.();
      this.confirmation();
    },
  },
};
</script>
