import isNil from 'lodash.isnil';
import appConstants from '@/constants/app';

const { STATUS } = appConstants;
const { OK } = STATUS;

/**
 * @description Check if the Vuex module is valid
 * @param {String} moduleName - Module name to register
 * @param {VuexModule} storeModule - Vuex module to register
 * @returns {Boolean}
 */
function isValidModule(moduleName, storeModule) {
  if (typeof moduleName !== 'string') {
    return false;
  }

  if (isNil(storeModule) || !storeModule.namespaced) {
    return false;
  }

  return true;
}

/**
 * @description Check if the module is not already register
 * @param {Object} store - Vuex store
 * @param {String} moduleName - Module name to register
 * @returns {Boolean}
 */
function isAlreadyRegistered(store, moduleName) {
  return store && store.state && store.state[moduleName];
}

/**
 * @description Mixin for register dynamically a Vuex module
 * @return {VueInstance}
 */
export default {
  methods: {
    /**
     * @description Register a Vuex module
     * @param {String} moduleName - Module name to register
     * @param {VuexModule} storeModule - Vuex module to register
     */
    registerStoreModule(moduleName, storeModule) {
      if (!isValidModule(moduleName, storeModule)) {
        return Promise.reject(new Error('app.error.store.invalid'));
      }

      if (!isAlreadyRegistered(this.$store, moduleName)) {
        this.$store.registerModule(moduleName, storeModule);
      }

      return OK;
    },

    isModuleRegistered(moduleName) {
      return isAlreadyRegistered(this.$store, moduleName);
    },
  },
};
