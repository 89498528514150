import appConstants from '@/constants/app';

const { EXPIRE_AT_SESSION } = appConstants.AUTH;

/**
 * @description Check if the user has an active session
 * @returns  {Boolean}
 */
export default function hasActiveSession() {
  const expireAt = parseInt(localStorage.getItem(EXPIRE_AT_SESSION), 10);
  return new Date().getTime() < expireAt;
}
