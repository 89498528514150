import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';

// App
const { PRODUCTS, TENANT } = appConstants;
const { COUPONPASS } = PRODUCTS;

// Routes
const { couponpass } = routesNames;

// i18n
const product = `app.products.${COUPONPASS}`;

/**
 * @description Get subscriptions data
 * @this Vue component
 * @returns {Object}
 */

export default function couponpassData() {
  const cumulTenants = [TENANT.VH];
  return {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, COUPONPASS),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description.${this.getTenant}`),
    sections: [
      {
        icon: this.$t('app.icons.dashboard'),
        title: this.$t(`${product}.sections.dashboard.title`),
        link: !cumulTenants.includes(this.getTenant)
          ? `/${couponpass.main}/${couponpass.dashboard.main}` : `/${couponpass.main}/${couponpass.dashboard.cumulio}`,
      },
    ],
  };
}
