import appConstants from '@/constants/app';

const { TENANT } = appConstants;

const allowedTenants = [
  TENANT.EDO,
  TENANT.CVO,
];

/**
 * Checks if the given tenant has access to chatbot settings
 *
 * @param {string} tenant - The tenant code to check
 * @returns {boolean}
 */
export default function hasAccessToChatbotSettings(tenant) {
  return allowedTenants.includes(tenant);
}
