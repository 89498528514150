<template>
  <v-list
    nav
    dense
  >
    <!--Smart alerts-->
    <SmartAlerts />

    <!--Settings-->
    <v-list-item
      v-if="hasUserAdminPrivileges"
      :title="menu.settings.title"
      @click="menu.settings.handler"
    >
      <v-list-item-action>
        <v-icon>
          {{ menu.settings.icon }}
        </v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ menu.settings.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--Profile-->
    <v-list-item
      :title="menu.profile.title"
      @click="menu.profile.handler"
    >
      <v-list-item-action>
        <v-icon>
          {{ menu.profile.icon }}
        </v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ menu.profile.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--Mini-->
    <v-list-item
      :title="menu.mini.title"
      @click="menu.mini.handler"
    >
      <v-list-item-action>
        <v-icon>
          {{ menu.mini.icon }}
        </v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ menu.mini.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';
import getBottomMenu from '@/components/app/side-navbar/bottom/utils/getBottomMenu';
import SmartAlerts from '@/components/app/side-navbar/bottom/smart-alerts/smart-alerts.vue';

const { APP } = storeConstants;
const { SIDE_NAVBAR } = appConstants;
const { MENU } = SIDE_NAVBAR;

export default {
  name: 'SideNavBarBottom',

  components: {
    SmartAlerts,
  },

  data() {
    return {
      menu: getBottomMenu.call(this),
    };
  },

  computed: {
    ...mapGetters({
      mini: `${APP.MENU}/sideNavBarMini`,
      hasUserAdminPrivileges: `${APP.USER_INFO}/hasUserAdminPrivileges`,
    }),
  },

  watch: {
    /**
     * Watch for the property 'mini' of component to change the arrow icon
     */
    mini() {
      this.toggleIcon();
    },
  },

  methods: {
    ...mapActions({
      setContextualType: `${APP.MENU}/setContextualType`,
      toggleSideNavBarMini: `${APP.MENU}/toggleSideNavBarMini`,
    }),

    /**
     * Handle settings
     */
    handleSettings() {
      this.setContextualType(MENU.SETTINGS);
    },

    /**
     * Handle profile
     */
    handleProfile() {
      this.setContextualType(MENU.PROFILE);
    },

    /**
     * Handle side nav bar mini
     */
    handleSideNavBarMini() {
      this.toggleSideNavBarMini();
    },

    /**
     * Toggle the icon
     */
    toggleIcon() {
      const expand = 'app.icons.chevronLeft';
      const collapse = 'app.icons.chevronRight';

      this.menu.mini.icon = this.mini ? this.$t(collapse) : this.$t(expand);
    },
  },
};
</script>
