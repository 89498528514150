export default {
  CAMPAIGN: {
    STATUS: {
      ALL: 'ALL',
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      EXPIRED: 'EXPIRED',
      CREATED: 'CREATED',
      COMPLETED: 'COMPLETED',
    },
    CLOSING_REASON: {
      MANUAL: 'MANUAL',
      GOAL_REACHED: 'GOAL_REACHED',
      EXPIRED: 'EXPIRED',
    },
    TABLE: {
      HEADER: {
        PROGRAM_ID: 'programId',
        PROGRAM_ID_LONG: 'programIdLong',
        PROGRAM_NAME: 'programName',
        CAMPAIGN_ID: 'campaignId',
        CAMPAIGN_ID_LONG: 'campaignIdLong',
        FLIGHT: 'flight',
        DEPARTURE_DATE: 'departureDate',
        DEPARTURE_STATION: 'departureStation',
        ARRIVAL_STATION: 'arrivalStation',
        PAXS_ELIGIBLE: 'paxsEligible',
        PAXS_CONTACTED: 'paxsContacted',
        PAXS_MOVED: 'paxsMoved',
        STATUS: 'status',
      },
    },
  },
};
