import configuration from './configuration';
import usersManagement from './users-management';
import promos from './promos';
import catalogs from './catalogs';

export default {
  subscriptions: {
    configuration,
    usersManagement,
    promos,
    catalogs,
  },
};
