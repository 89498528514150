import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';
import isDemoTenant from '@/utils/isDemoTenant';

// App
const { TENANT, PRODUCTS } = appConstants;
const { UPSELLS } = PRODUCTS;

// Routes
const { upsells } = routesNames;

// i18n
const product = `app.products.${UPSELLS}`;

/**
 * @description Get programs section
 * @this Vue component
 * @returns {Object}
 */
function getProgramsSection() {
  return [{
    icon: this.$t('app.icons.list'),
    title: this.$t(`${product}.sections.programs.title`),
    link: `/${upsells.main}/${upsells.programs.main}`,
  }];
}

/**
 * @description Get dashboard section
 * @this Vue component
 * @returns {Object}
 */
function getDashboardSection() {
  return [{
    icon: this.$t('app.icons.dashboard'),
    title: this.$t(`${product}.sections.dashboard.title`),
    link: `/${upsells.main}/${upsells.dashboard.main}`,
  }];
}

/**
 * @description Get AirAsia dashboard
 * @param {Object} data - The default data (photo, title, description, sections)
 * @this Vue component
 * @returns {Object}
 */
function getAirAsiaDashboard(data) {
  const sections = [
    {
      icon: this.$t('app.icons.dashboard'),
      title: this.$t(`${product}.menu.D7.overall`),
      link: `/${upsells.main}/${upsells.dashboard.main}/overall`,
    },
    {
      icon: this.$t('app.icons.dashboard'),
      title: this.$t(`${product}.menu.D7.malaysia`),
      link: `/${upsells.main}/${upsells.dashboard.main}/${TENANT.D7}`,
    },
    {
      icon: this.$t('app.icons.dashboard'),
      title: this.$t(`${product}.menu.D7.thai`),
      link: `/${upsells.main}/${upsells.dashboard.main}/${TENANT.XJ}`,
    },
  ];

  return {
    ...data,
    sections,
  };
}

/**
 * @description Get default sections and programs
 * @param {Object} data - The default data (photo, title, description, sections)
 * @this Vue component
 * @returns {Object}
 */
function getDefaultSectionsAndPrograms(data) {
  const programs = getProgramsSection.call(this);

  return {
    ...data,
    sections: [
      ...data.sections,
      ...programs,
    ],
  };
}

/**
 * @description Get default sections and dashboard
 * @param {Object} data - The default data (photo, title, description, sections)
 * @param {Object} dashboard - The dashboard section (icon, title, link)
 * @this Vue component
 * @returns {Object}
 */
function getDefaultSectionsAndDashboard(data) {
  const dashboard = getDashboardSection.call(this);

  return {
    ...data,
    sections: [
      ...dashboard,
      ...data.sections,
    ],
  };
}

/**
 * @description Get default sections
 * @this Vue component
 * @returns {Object}
 */
function getDefaultSections() {
  return [
    // Bids
    {
      icon: this.$t('app.icons.gavel'),
      title: this.$t(`${product}.sections.bids.title.${this.getTenant}`),
      link: `/${upsells.main}/${upsells.bids.main}`,
    },
  ];
}

/**
 * @description Get default data
 * @this Vue component
 * @returns {Object}
 */
function getDefaultData() {
  const defaultSections = getDefaultSections.call(this);

  return {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, UPSELLS),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description.${this.getTenant}`),
    sections: defaultSections,
  };
}

/**
 * @description Upsells data
 * @this Vue component
 * @returns {Object}
 */
export default function upsellsData() {
  const data = getDefaultData.call(this);

  /**
   * Upsells: get default sections and programs
   * CLMBS-801: Upsells programs is only visible for demo tenant
   */
  if (isDemoTenant(this.getTenant)) {
    return getDefaultSectionsAndPrograms.call(this, data);
  }

  /**
   * Upsells: get only dashboard
   * CLMBS-1026: AirAsia (D7)
   */
  if (this.getTenant === TENANT.D7) {
    return getAirAsiaDashboard.call(this, data);
  }

  /**
   * Upsells: get default sections and dashboard
   * CLMBS-981: Scoot (TZ)
   * CLMBS-1059: Eurowings (4U)
   */
  if (this.getTenant === TENANT.TZ || this.getTenant === TENANT['4U']) {
    return getDefaultSectionsAndDashboard.call(this, data);
  }

  /**
   * In any other case...
   */
  return data;
}
