import storeConstants from '@/constants/store';
import getAccessToken from '@/utils/getAccessToken';

const { APP } = storeConstants;

/**
 * Get API configuration
 * - The token will be used by Axios
 * - The userInfo will be used by Sentry
 *
 * @param {Object} rootGetters - Getters from Vuex
 * @returns {Object}
 */
export default async function getApiConfiguration(rootGetters) {
  return {
    token: await getAccessToken(),
    userInfo: {
      name: rootGetters[`${APP.USER_INFO}/getUserName`],
      role: rootGetters[`${APP.USER_INFO}/getUserRole`],
      tenant: rootGetters[`${APP.USER_INFO}/getTenant`],
    },
  };
}
