const SHOW = 'show';
const HIDE = 'hide';

const initialState = {
  status: '',
  show: false,
  message: '',
};

const mutations = {
  [SHOW](state, payload) {
    state.show = true;
    state.status = payload.status;
    state.message = payload.message;
  },
  [HIDE](state) {
    state.show = false;
    state.status = '';
    state.message = '';
  },
};

const actions = {
  [SHOW]({ commit }, payload) {
    commit(SHOW, payload);
  },
  [HIDE]({ commit }) {
    commit(HIDE);
  },
};

const getters = {};

const state = initialState;

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
