import types from './utils/types';

const {
  SAVE_PROGRAMS,
} = types;

export default {
  [SAVE_PROGRAMS](state, payload) {
    state.programs = payload;
  },
};
