import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { main, routes, groups } = endpoints.masterData;

/**
 * @description sends a request to the server for update flying routes
 * @param {Object} payload - object with the list of routes, token and user info
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#master_data_routes_put}
 */
async function updateRoutes(payload) {
  const {
    list,
    token,
    userInfo,
  } = payload;

  try {
    const response = await axios.put(`${main}/${routes}`, list, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description sends a request to the server for update groups
 * @param {Object} payload - object with the list of groups, token and user info
 * @returns {Promise}
 * @see {@link hhttps://documentation.caravelo.net/columbus-api/#master_data_groups_put}
 */
async function updateGroups(payload) {
  const {
    list,
    token,
    userInfo,
  } = payload;

  try {
    const response = await axios.put(`${main}/${groups}`, list, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  masterdata: {
    updateRoutes,
    updateGroups,
  },
};
