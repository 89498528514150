import auth from '@/middleware/auth';
import routesNames from '@/router/routes-names';
import hasActiveSession from '@/middleware/auth/utils/hasActiveSession';
import store from '@/store';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;

async function hasRoleAccess(to) {
  if (!to.meta || !to.meta.authorize) {
    return true;
  }

  let userRole = store.getters[`${APP.USER_INFO}/getUserRole`];
  if (!userRole) {
    // eslint-disable-next-line no-underscore-dangle
    await store._actions[`${APP.USER_INFO}/requestProfile`][0]();
    userRole = store.getters[`${APP.USER_INFO}/getUserRole`];
  }
  return to.meta.authorize?.includes(userRole);
}

function isRouteEnabled(to) {
  if (to.meta && to.meta.isEnabled?.() === false) {
    return false;
  }
  return true;
}

/**
 * Vue Router - Guards
 */
export default async function routeGuards(to, from, next) {
  // TODO: this should be removed as it might be a security risk
  if (window.Cypress) {
    next();
  } else if (to.name === routesNames.callback) {
    next();
  } else if (hasActiveSession()) {
    const hasAccess = await hasRoleAccess(to);
    const isEnabled = isRouteEnabled(to);
    if (hasAccess && isEnabled) {
      next();
    } else {
      next({ path: '*', redirect: '/' });
    }
  } else {
    auth.then((client) => client.login(to.path));
  }
}
