import routesNames from '@/router/routes-names';

const { chatbot } = routesNames;

const Settings = () => import(/* webpackChunkName: "async-bot-settings" */ '@/views/chatbot/settings/settings.vue');

const HandoverOpeningHours = () => import('@/views/chatbot/settings/opening-hours/opening-hours.vue');

const HandoverOverrideQueue = () => import('@/views/chatbot/settings/queue-override/queue-override.vue');

const HandoverRestrictions = () => import('@/views/chatbot/settings/handover-restrictions/handoverrestrictions.vue');

/**
 * @description Get chatbot settings route
 * @returns {Array<Object>}
 */
export default [{
  component: Settings,
  path: `/${chatbot.main}/${chatbot.settings.main}`,
  name: `${chatbot.main}.${chatbot.settings.main}`,
  children: [
    {
      path: `${chatbot.settings.opening}`,
      component: HandoverOpeningHours,
      name: `${chatbot.main}.${chatbot.settings.main}.${chatbot.settings.opening}`,
    },
    {
      path: `${chatbot.settings.queue}`,
      component: HandoverOverrideQueue,
      name: `${chatbot.main}.${chatbot.settings.main}.${chatbot.settings.queue}`,
    },
    {
      path: `${chatbot.settings.handoverrestrictions}`,
      component: HandoverRestrictions,
      name: `${chatbot.main}.${chatbot.settings.main}.${chatbot.settings.handoverrestrictions}`,
    },
  ],
}];
