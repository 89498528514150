/**
 * @description Columbus API endpoints
 * @url http://documentation.caravelo.net/columbus-api
 */
export default {
  user: '/user',
  currentDate: '/current-date',
  updateUserLoginInfo: '/update-user-login-info',
  users: {
    main: '/users',
  },
  cms: {
    main: '/cms',
    charts: 'charts',
    messages: 'messages',
    intentsDictionary: 'intents-dictionary',
    iata: {
      main: 'iata',
      airports: 'airports',
    },
  },
  chatbot: {
    configuration: '/chatbots/configuration',
    reportUpdate: '/chatbots/configuration/report-update',
  },
  subscriptions: {
    main: '/subscriptions',
    configuration: '/subscriptions/configuration',
    users: '/subscriptions/subscribers?key=',
    userDetails: '/subscriptions/subscribers/',
    subs: 'subscribers/',
    timeline: '/timeline',
    invoice: 'subscriptions/invoices/',
    listBeneficiaries: {
      beneficiaries: '/beneficiaries/',
      main: 'subscriptions/subscribers/',
      subscriptions: '/subscriptions/',
      deleteBeneficiaries: 'delete-beneficiaries/',
    },
    payAllInvoices: '/payAllInvoices',
    cumulioAuth: '/reports/auth/cumulio',
    catalog: '/subscriptions/catalogs/latest/new',
    subsPlan: '/subscribers',
    subsPlanSuffix: '/subscription',
    latestPayments: '/subscriptions/subscribers?sorting=LATEST_PAYMENT_DATE',
    promoCatalogs: '/subscriptions/catalogs/simple',
    promos: {
      main: '/subscriptions/promos/',
      promoDetailsParam: '?detailedView=true',
      addCodes: '/coupons/',
      downloadCodes: '/coupons/csv',
      coupons: 'coupons/',
      isValid: '/isValid',
    },
    catalogs: '/catalogs',
    subscriptions: '/subscriptions/',
    quota: '/quota',
  },
  giftVoucher: {
    configuration: '/gifts/configuration',
    vouchers: {
      main: '/gifts/vouchers',
      invoice: '/gifts/vouchers/invoice',
      issue: '/gifts/vouchers/issue',
      copy: '/gifts/vouchers/copy',
      emailIssue: 'gifts/vouchers/email/issue',
      emailInvoice: 'gifts/vouchers/email/invoice',
    },
  },
  masterData: {
    main: '/master-data',
    routes: 'routes',
    groups: 'groups',
  },
  exchange: {
    offers: {
      main: '/seatresale/offers',
      issueReward: 'issue-reward',
    },
    programs: {
      main: '/seatresale/programs',
      zip: 'zip',
      csv: 'csv',
      json: 'json',
      names: 'names',
      import: 'import',
      templates: '/seatresale/programstemplates',
    },
    campaigns: '/seatresale/campaigns',
    configuration: '/seatresale/configuration',
  },
  upsells: {
    bids: {
      main: '/upsells/bids',
      refund: 'refund',
    },
    configuration: '/upsells/configuration',
  },
  alerts: {
    main: '/notifications',
    programs: 'programs',
    expired: 'expired',
  },
  logs: {
    main: '/logs',
  },
  reports: {
    main: '/reports',
    download: '/download',
    encoded: 'encoded',
  },
  couponpass: {
    configuration: '/couponpass/configuration',
  },
};
