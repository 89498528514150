// Vendors
import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import app from '@/router/routes/app';
import search from '@/router/routes/search';
import chatbot from '@/router/routes/chatbot';
import subscriptions from '@/router/routes/subscriptions';
import couponpass from '@/router/routes/couponpass';
import upsells from '@/router/routes/upsells';
import exchange from '@/router/routes/exchange';
import profile from '@/router/routes/profile';
import settings from '@/router/routes/settings';
import smartAlerts from '@/router/routes/smart-alerts';
import giftVoucher from '@/router/routes/gift-voucher';
import reports from '@/router/routes/reports';

/**
 * Create an instance of Vue Router
 * and configure the Columbus routes
 *
 * @returns {VueRouter}
 */
export default function routerInstance() {
  Vue.use(VueRouter);

  return new VueRouter({
    mode: 'history',
    routes: [
      ...app,
      ...search,
      ...chatbot,
      ...subscriptions,
      ...couponpass,
      ...upsells,
      ...exchange,
      ...profile,
      ...settings,
      ...smartAlerts,
      ...giftVoucher,
      ...reports,
    ],
  });
}
