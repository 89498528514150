import offers from './offers';
import programs from './programs';
import campaigns from './campaigns';
import configuration from './configuration';

export default {
  exchange: {
    offers,
    programs,
    campaigns,
    configuration,
  },
};
