import initialState from '@/store/app/userInfo/initialState';
import actions from '@/store/app/userInfo/actions';
import getters from '@/store/app/userInfo/getters';
import mutations from '@/store/app/userInfo/mutations';

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
