import getters from '@/store/app/features/getters';
import initialState from '@/store/app/features/initialState';
import mutations from '@/store/app/features/mutations';

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
};
