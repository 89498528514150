import Bids from '@/router/routes/upsells/bids';
import Program from '@/router/routes/upsells/program';
import Programs from '@/router/routes/upsells/programs';
import Dashboard from '@/router/routes/upsells/dashboard';

export default [
  ...Bids,
  ...Program,
  ...Programs,
  ...Dashboard,
];
