import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapPromoCreatePayload from '../utils/mapPromoCreatePayload';

const { promos } = endpoints.subscriptions;

async function getPromos({ token, userInfo }) {
  try {
    const response = await axios.get(promos.main, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getPromo({ token, userInfo }, payload) {
  try {
    const response = await axios.get(`${promos.main}${payload}${promos.promoDetailsParam}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function saveNewPromo({ token, userInfo }, payload) {
  try {
    const newPayload = mapPromoCreatePayload(payload);
    const response = await axios.post(promos.main, newPayload, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function updatePromo({ token, userInfo }, payload) {
  try {
    const response = await axios.put(`${promos.main}${payload.id}`, payload, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function addPromoCodes({ token, userInfo }, payload) {
  try {
    const path = `${promos.main}${payload.promoId}${promos.addCodes}`;
    const response = await axios.post(path, payload, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function downloadPromoCodes({ token, userInfo }, payload) {
  try {
    const path = `${promos.main}${payload}${promos.downloadCodes}`;
    const response = await axios.get(path, axiosConfiguration(token));
    return response.data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function validateCode({ token, userInfo }, payload) {
  try {
    const path = `${promos.main}${promos.coupons}${payload}${promos.isValid}`;
    const response = await axios.get(path, axiosConfiguration(token));
    return response.data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getPromos,
  getPromo,
  saveNewPromo,
  updatePromo,
  downloadPromoCodes,
  addPromoCodes,
  validateCode,
};
