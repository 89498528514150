import api from '@/middleware/api';
import types from '@/store/app/serverDate/utils/types';
import getApiConfiguration from '@/store/utils/getApiConfiguration';

const { SAVE } = types;

/**
 * @description Request current date
 * @param {Function} commit from Vuex
 * @param {Object} rootGetters from Vuex
 * @returns {Promise}
 */
async function requestCurrentDate({ commit, rootGetters }) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    const { data, status } = await api.app.getCurrentDate({ ...apiConfiguration });

    commit(SAVE, data);

    return { status };
  } catch (error) {
    throw new Error('app.error.serverDate.request');
  }
}

export default {
  requestCurrentDate,
};
