/**
 * @description Get ISO Date
 * @param {Object} state - state of Vuex module
 * @returns {String} - Example: '2011-10-05T14:48:00.000Z'
 */
const getServerISODate = (state) => state.current;

/**
 * @description Get Object Date
 * @param {Object} state - state of Vuex module
 * @returns {Date} - Date Object
 */
const getServerObjectDate = (state) => new Date(state.current);

export default {
  getServerISODate,
  getServerObjectDate,
};
