import appConstants from '@/constants/app';

const { ENV } = appConstants;

export default function axiosConfiguration(token) {
  return {
    timeout: 30000,
    baseURL: (process.env.NODE_ENV === ENV.DEVELOPMENT) ? '/api' : `${process.env.VUE_APP_API}/api`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
