import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapUserParametersToQuery from '@/middleware/api/utils/mapUserParametersToQuery';

const { users } = endpoints;

/**
 * Get users by tenant
 *
 * @async
 * @param {Object} payload
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @param {Object} payload.params - The filters for requesting a specific list of users
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#logs_get}
*/
async function getUsers(payload) {
  const { params, token, userInfo } = payload;

  try {
    const query = mapUserParametersToQuery(params);
    const response = await axios.get(`${users.main}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Get user by id
 *
 * @async
 * @param {Object} payload
 * @param {Object} payload.id - The user id
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#users__id__get}
*/
async function getUser(payload) {
  const { id, token, userInfo } = payload;

  try {
    const { data } = await axios.get(`${users.main}/${id}`, axiosConfiguration(token));
    return data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Create user
 *
 * @async
 * @param {Object} payload
 * @param {Object} payload.user - The new user to create
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#users_post}
*/
async function createUser(payload) {
  const { user, token, userInfo } = payload;

  try {
    const data = await axios.post(`${users.main}`, user, axiosConfiguration(token));
    return data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}
/**
 * Update user
 *
 * @async
 * @param {Object} payload
 * @param {Object} payload.userId - The user id
 * @param {Object} payload.userData - The user data to update
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#users__id__patch}
*/
async function updateUser(payload) {
  const {
    userId,
    userData,
    token,
    userInfo,
  } = payload;

  try {
    const path = `${users.main}/${userId}`;
    const data = await axios.patch(path, userData, axiosConfiguration(token));
    return data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Delete user
 *
 * @async
 * @param {Object} payload
 * @param {Object} payload.id - The user id
 * @param {String} payload.token - The access token
 * @param {Object} payload.userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#users__id__delete}
*/
async function deleteUser(payload) {
  const { id, token, userInfo } = payload;

  try {
    const data = await axios.delete(`${users.main}/${id}`, axiosConfiguration(token));
    return data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  users: {
    getUser,
    getUsers,
    deleteUser,
    createUser,
    updateUser,
  },
};
