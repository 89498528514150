import campaignConstants from '@/constants/campaign';

const { CAMPAIGN } = campaignConstants;
const { ROUTE, DEPARTURE_STATION } = CAMPAIGN.TABLE.HEADER;
const { STATUS, CLOSING_REASON } = CAMPAIGN;

/**
 * @description Get default query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Array}
 */
function queryPageAndPerPage(page, perPage) {
  return [
    `page=${page}`,
    `per_page=${perPage}`,
  ];
}

function getSortQuery(field) {
  if (field === ROUTE) {
    /**
     * Note: because 'route' is a mix between
     * 'departureStation' and 'arrivalStation'
     * we need to sort by one of them
     */
    return DEPARTURE_STATION;
  }

  return field;
}

/**
 * @description Add sort params to query
 * @param {Array} query
 * @param {Object} sort
 * @returns {Array} a copy of query with order and sort
 */
function querySortAndOrder(sort) {
  const query = [];

  if (sort.length > 0) {
    const [data] = sort;
    const { dir, field } = data;

    query.push(`order=${dir}`);
    query.push(`sort=${getSortQuery(field)}`);
  }

  return query;
}

/**
 * @description Get query by program id
 * @param {String} programId
 * @return {Array}
 */
function queryByProgramId(programId) {
  const query = [];

  if (programId) {
    query.push(`programId=${programId}`);
  }

  return query;
}

/**
 * @description Get query by status and closing reason
 * @param {String} status
 * @return {Array} Filter by status or closing reason
 */
function queryStatus(status) {
  const query = [];

  if (status) {
    switch (status) {
      case STATUS.ALL:
        query.push(`status=${STATUS.ACTIVE}&status=${STATUS.CLOSED}`);
        break;
      case STATUS.ACTIVE:
        query.push(`status=${STATUS.ACTIVE}`);
        break;
      case STATUS.CLOSED:
        query.push(`status=${STATUS.CLOSED}&closingReason=${CLOSING_REASON.MANUAL}`);
        break;
      case STATUS.EXPIRED:
        query.push(`status=${STATUS.CLOSED}&closingReason=${CLOSING_REASON.EXPIRED}`);
        break;
      case STATUS.COMPLETED:
        query.push(`status=${STATUS.CLOSED}&closingReason=${CLOSING_REASON.GOAL_REACHED}`);
        break;
      default:
        break;
    }
  }

  return query;
}

/**
 * @description Get query by carrier code
 * @param {String} carrierCode  code of carrier to filter
 * @return {Array} Filters by carrier code
 */
function queryCarrierCode(carrierCode) {
  const query = [];

  if (carrierCode) {
    query.push(`carrierCode=${carrierCode}`);
  }

  return query;
}

/**
 * @description Create a query parameters for requesting a list of campaigns
 * @param {Object} params - parameters for requesting a specific list of campaigns
 * @example: page=1&per_page=10&sort=updatedAt&order=desc
 * @returns {String} params queries for API
 */
export default function mapCampaignParametersToQuery(params) {
  const {
    sort,
    page,
    perPage,
    programId,
    status,
    carrierCode,
  } = params;

  const query = [
    ...queryByProgramId(programId),
    ...querySortAndOrder(sort),
    ...queryPageAndPerPage(page, perPage),
    ...queryStatus(status),
    ...queryCarrierCode(carrierCode),
  ];

  return query.join('&');
}
