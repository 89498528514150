import axios from 'axios';
import isNil from 'lodash.isnil';
import isEmpty from 'lodash.isempty';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapOfferParametersToQuery from '@/middleware/api/utils/mapOfferParametersToQuery';

const { offers } = endpoints.exchange;

/**
 * @description Get offers by pnr
 * @param {String} pnr - offer pnr
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_offers__pnr__get}
 */
async function getOffers(payload) {
  const { pnr, token, userInfo } = payload;

  if (isNil(pnr)) {
    return Promise.reject(new Error('app.error.pnr'));
  }

  try {
    const response = await axios.get(`${offers.main}/${pnr}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Update the status of an offer
 * @param {String} id - offer id
 * @param {Object} data - offer status (close or open) and expiration date (only for open)
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_offers__id__patch}
 */
async function updateStatus(payload) {
  const {
    id,
    data,
    token,
    userInfo,
  } = payload;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.offer.id'));
  }

  if (isNil(data) || isEmpty(data)) {
    return Promise.reject(new Error('app.error.offer.data'));
  }

  try {
    const response = await axios.patch(`${offers.main}/${id}`, data, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Issue reward
 * @param {String} id - offer id
 * @param {String} rewardType - reward type
 * @param {Number} paxId - passenger id
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_offers__id__issue_reward_post}
 */
async function issueReward(payload) {
  const {
    id,
    data,
    token,
    userInfo,
  } = payload;

  const { rewardType, paxId, sendEmail } = data;

  if (isNil(id)) {
    return Promise.reject(new Error('app.error.offer.id'));
  }

  if (isNil(rewardType)) {
    return Promise.reject(new Error('app.error.offer.rewardType'));
  }

  if (isNil(paxId)) {
    return Promise.reject(new Error('app.error.offer.paxId'));
  }

  try {
    const params = mapOfferParametersToQuery(rewardType, paxId, sendEmail);
    const path = `${offers.main}/${id}/${offers.issueReward}?${params}`;
    const response = await axios.post(path, {}, axiosConfiguration(token));

    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getOffers,
  issueReward,
  updateStatus,
};
