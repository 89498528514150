import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';
import hasAccessToChatbotSettings from '@/utils/hasAccessToChatbotSettings';

// App
const { TENANT, PRODUCTS } = appConstants;
const { CHATBOT } = PRODUCTS;

// Routes
const { chatbot } = routesNames;
// i18n
const product = `app.products.${CHATBOT}`;

/**
 * @description Chatbot data
 * @this Vue component
 * @returns {Object}
 */
export default function chatbotData() {
  const cumulTenants = [TENANT['0B'], TENANT.WO];
  const menuData = {
    icon: this.$t(`${product}.icon`),
    photo: getProductImage(this.getTenant, CHATBOT),
    title: this.$t(`${product}.title.${this.getTenant}`),
    description: this.$t(`${product}.description.${this.getTenant}`),
    sections: [
      {
        icon: this.$t('app.icons.dashboard'),
        title: this.$t(`${product}.sections.dashboard.title`),
        link: !cumulTenants.includes(this.getTenant)
          ? `/${chatbot.main}/${chatbot.dashboard.main}` : `/${chatbot.main}/${chatbot.dashboard.cumulio}`,
      },

    ],
  };
  if (hasAccessToChatbotSettings(this.getTenant)) {
    menuData.sections.push({
      icon: this.$t('app.icons.settings'),
      title: this.$t(`${product}.sections.settings.title`),
      link: `/${chatbot.main}/${chatbot.settings.main}`,
    });
  }
  return menuData;
}
