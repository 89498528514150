import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import { getProductImage } from '@/utils/theme';

// App
const { REPORTS } = appConstants.SECTIONS;

// Routes
const { reports } = routesNames;

// i18n
const section = `app.${REPORTS}`;

/**
 * @description Reports data
 * @this Vue component
 * @returns {Object}
 */
export default function chatbotData() {
  return {
    icon: this.$t('app.icons.reports'),
    photo: getProductImage(this.getTenant, REPORTS),
    title: this.$t(`${section}.title`),
    description: this.$t(`${section}.description`),
    sections: [
      {
        icon: this.$t('app.icons.reports'),
        title: this.$t(`${section}.title`),
        link: `/${reports.main}`,
      },
    ],
  };
}
