import api from '@/middleware/api';
import appConstants from '@/constants/app';
import getAccessToken from '@/utils/getAccessToken';
import mapId from '@/store/app/userInfo/utils/mapId';
import types from '@/store/app/userInfo/utils/types';
import getApiConfiguration from '@/store/utils/getApiConfiguration';
import isPromosEnabled from '@/store/app/features/utils/isPromosEnabled';
import featuresTypes from '@/store/app/features/utils/types';
import storeConstants from '@/constants/store';

const { STATUS } = appConstants;
const { OK } = STATUS;
const {
  SAVE_ID,
  SAVE_ROLE,
  SAVE_NAME,
  SAVE_EMAIL,
  SAVE_TENANT,
  SAVE_PICTURE,
  SAVE_PRODUCTS,
} = types;

const { APP } = storeConstants;
const { SET_PROMOS_ENABLED } = featuresTypes;

/**
 * Request the profile and save it on Vuex
 *
 * @param {Function} commit from Vuex
 * @returns {Promise}
 */
async function requestProfile({ commit }) {
  try {
    const token = await getAccessToken();
    const { data } = await api.profile.getProfile({ token });

    commit(SAVE_ID, mapId(data.userId));
    commit(SAVE_ROLE, data.role);
    commit(SAVE_NAME, data.name);
    commit(SAVE_EMAIL, data.email);
    commit(SAVE_TENANT, data.tenant);
    commit(SAVE_PICTURE, data.picture);
    commit(SAVE_PRODUCTS, data.products);
    /**
     * TODO: CVO-7865 this is a quick fix.
     * enabled/disabled status should be provided by API. isPromosEnabled should be removed
     */
    commit(`${APP.FEATURES}/${SET_PROMOS_ENABLED}`, isPromosEnabled(data.tenant), { root: true });

    return OK;
  } catch (error) {
    throw new Error('app.error.userInfo.request');
  }
}

/**
 * Update profile and save the name on Vuex
 *
 * @param {Object} context - context from Vuex
 * @param {Function} context.commit from Vuex
 * @param {Object} context.rootGetters - rootGetters from Vuex
 * @param {Object} payload - The profile data: name or password
 * @returns {Promise}
 */
async function updateProfile({ commit, rootGetters }, payload) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);

    const { data } = await api.profile.updateProfile({
      profileData: payload,
      ...apiConfiguration,
    });

    commit(SAVE_NAME, data.name);

    return STATUS.OK;
  } catch (error) {
    /**
     * Auth0 response messages
     * https://auth0.com/docs/api/management/v2#!/Users/patch_users_by_id
     */
    const { response } = error;

    if (response && response.status === 404) {
      throw Error('profile.error.update.status404');
    }

    throw Error('profile.error.update.default');
  }
}

export default {
  updateProfile,
  requestProfile,
};
