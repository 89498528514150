import routesNames from '@/router/routes-names';

const { subscriptions } = routesNames;

const UsersManagement = () => import('@/views/subscriptions/users-management/users-management.vue');

/**
 * @description Get subscriptions user management route
 * @returns {Array<Object>}
 */
export default [{
  component: UsersManagement,
  path: `/${subscriptions.main}/${subscriptions.management.main}`,
  name: `${subscriptions.main}.${subscriptions.management.main}`,
}];
