import VueTypes from 'vue-types';

const section = VueTypes.shape({
  title: VueTypes.string,
  icon: VueTypes.string,
  link: VueTypes.string,
  menu: VueTypes.array,
  enabled: VueTypes.bool,
  authorize: VueTypes.array,
});

const product = {
  icon: VueTypes.string.isRequired,
  title: VueTypes.string.isRequired,
  description: VueTypes.string.isRequired,
  photo: VueTypes.string.isRequired,
  sections: VueTypes.arrayOf(section).isRequired,
};

export default {
  product,
};
