import types from '@/store/app/menu/utils/types';

const {
  TOGGLE_MINI,
  SET_CONTEXTUAL_TYPE,

  TOGGLE_PROFILE,
  SET_PROFILE_TOP_POSITION,
} = types;

export default {
  [TOGGLE_MINI](state) {
    state.mini = !state.mini;
  },

  [SET_CONTEXTUAL_TYPE](state, payload) {
    state.contextual.type = payload;
  },

  [TOGGLE_PROFILE](state) {
    state.profile.show = !state.profile.show;
  },

  [SET_PROFILE_TOP_POSITION](state, payload) {
    state.profile.topPosition = payload;
  },
};
