import auth from '@/middleware/auth';

/**
 * @description CLMBS-922: Cypress must request access token from localStorage
 */
function getCypressToken() {
  const token = localStorage.getItem('accessToken');
  return token;
}

/**
 * @description Get token from Auth0 middleware
 */
function getClientToken() {
  try {
    return auth.then(async (client) => {
      const token = await client.getTokenSilently();
      return token;
    });
  } catch (error) {
    return auth.then((client) => client.logout());
  }
}

/**
 * @description Get access token from Auth0
 * @returns {Promise}
 */
export default async function getAccessToken() {
  if (window.Cypress) {
    return getCypressToken();
  }

  return getClientToken();
}
