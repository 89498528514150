import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { configuration, reportUpdate } = endpoints.chatbot;

/**
* @description Get Chatbot configuration
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#chatbots_configuration_get}
*/
async function getConfiguration({ token, userInfo }) {
  try {
    const response = await axios.get(configuration, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description sends a request to the server to update configuration
 * @param {Object} payload - object with the settings
 * @returns {Promise}
 */
async function updateConfiguration({ token, userInfo }, payload) {
  try {
    const response = await axios.put(configuration, payload, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description sends an event regarding a configuration update
 * @param {Object} payload - object with the settings changes
 * @returns {Promise}
 */
async function sendUpdateEvent({ token, userInfo }, payload) {
  try {
    return axios.put(reportUpdate, payload, axiosConfiguration(token));
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getConfiguration,
  sendUpdateEvent,
  updateConfiguration,
};
