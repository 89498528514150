import program from '@/router/routes/exchange/program';
import programs from '@/router/routes/exchange/programs';
import campaigns from '@/router/routes/exchange/campaigns';
import dashboard from '@/router/routes/exchange/dashboard';

export default [
  ...program,
  ...programs,
  ...campaigns,
  ...dashboard,
];
