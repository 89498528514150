export default function initialState() {
  return {
    data: {
      type: '',
      carrier: '',
      defaultLocale: '',
      defaultCurrency: '',
      supportedLocales: [],
      currencies: [],
      cabins: [],
      flights: {
        flightCodes: [],
        routes: [],
        groups: [],
      },
      passengers: {
        ffp: [],
        types: [],
      },
      fares: {
        rbd: [],
        brandedFares: [],
        fareBasisCodes: [],
        classesOfService: [],
      },
      ssrs: [],
      pointOfSales: [],
      sr: {
        rewards: [],
      },
      aircrafts: [],
      upsellsMasterData: {
        taxes: [],
        flowExecution: [],
        upgradeMasterData: {
          defaultExclusions: [],
          ancillaryGroups: [],
          channelConfigurations: [],
        },
        extraseatMasterData: {
          dummyConfiguration: {},
          channelConfigurations: {},
        },
      },
    },
  };
}
