import './loading.scss';

function toggleClass(el, binding) {
  if (binding.value) {
    el.classList.add('is-loading');
    el.classList.add('default-loader');
    el.setAttribute('disabled', 'disabled');
  } else {
    el.classList.remove('is-loading');
    el.classList.remove('default-loader');
    el.removeAttribute('disabled');
  }
}

export default {
  bind(el, binding) {
    toggleClass(el, binding);
  },

  update(el, binding) {
    toggleClass(el, binding);
  },
};
