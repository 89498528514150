import programConstants from '@/constants/program';

const { PROGRAMS } = programConstants;
const { ORDER } = PROGRAMS.PAGINATION;

/**
 * @description Get pagination order
 * @param {Boolean} order
 * @returns {String}
 */
const getOrder = (order) => {
  if (order) {
    return ORDER.ASC;
  }

  return ORDER.DESC;
};

/**
 * @description Create a multiple status query
 * @param {String} statusQuery
 * @example INACTIVE&status=CREATED&status=CLOSED
 * @returns {String} status queries for API
 */
const getMultipleStatus = (statusQuery) => statusQuery.reduce((accu, status, index, list) => {
  if (index < list.length - 1) {
    return `${accu}${status}&status=`;
  }

  return `${accu}${status}`;
}, '');

/**
 * @description Get status
 * @param {String} statusQuery
 * @returns {String}
 */
const getStatus = (statusQuery) => {
  if (Array.isArray(statusQuery)) {
    return getMultipleStatus(statusQuery);
  }

  return statusQuery;
};

/**
 * @description Create a query parameters for requesting a list of programs
 * @param {Object} params - parameters for requesting a specific list of programs
 * @example: page=1&per_page=10&sort=updatedAt&order=desc&status=ACTIVE
 * @returns {String} params queries for API
 */
export default function mapProgramParametersToQuery(params) {
  const {
    page,
    perPage,
    sort,
    order,
    statusQuery,
  } = params;

  const query = [
    `page=${page}`,
    `per_page=${perPage}`,
    `sort=${sort}`,
    `order=${getOrder(order)}`,
    `status=${getStatus(statusQuery)}`,
  ];

  return query.join('&');
}
