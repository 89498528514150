import appConstants from '@/constants/app';

const { EXPIRE_AT_SESSION } = appConstants.AUTH;

/**
 * @description Remove expiration date from localstorage
 */
export default async function removeExpireAtSession() {
  await localStorage.removeItem(EXPIRE_AT_SESSION);
  return true;
}
