import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const {
  users, userDetails, timeline, invoice, payAllInvoices, catalog,
  subsPlan, main, subsPlanSuffix, listBeneficiaries, latestPayments,
  subscriptions, quota,
} = endpoints.subscriptions;
const BENEFICIARY_V1 = 'application/vnd.cvo.subs.beneficiary-v1+json';
/**
* @description Get Subscriptions users
 * @param {String} payload - payloaded value
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
*/

let usersTimer;
function getUsers(payload, { token, userInfo }) {
  const DELAY = 500;

  if (usersTimer) {
    clearTimeout(usersTimer);
  }

  const response = new Promise((resolve, reject) => {
    usersTimer = setTimeout(async () => {
      try {
        const data = axios.get(`${users + payload}`, axiosConfiguration(token));
        resolve(data);
      } catch (error) {
        reportErrorToSentry(error, userInfo);
        reject(error);
        throw error;
      }
    }, DELAY);
  });

  return response;
}

async function getLatestAccounts({ token, userInfo }) {
  try {
    const response = await axios.get(`${userDetails}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getLatestPayments({ token, userInfo }) {
  try {
    const response = await axios.get(`${latestPayments}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getUserDetails(payload, { token, userInfo }) {
  try {
    const response = await axios.get(`${userDetails + payload + timeline}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getListBeneficiaries({ token, userInfo }, payload) {
  try {
    const response = await axios.get(`${listBeneficiaries.main}${payload.walletId}${listBeneficiaries.subscriptions}
${payload.bundleId}${listBeneficiaries.beneficiaries}`, axiosConfiguration(token));
    return response.data;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function saveBeneficiary({ token, userInfo }, payload) {
  try {
    const config = axiosConfiguration(token);
    config.headers['Content-Type'] = BENEFICIARY_V1;
    const response = await axios.post(`${listBeneficiaries.main}${payload.walletId}${listBeneficiaries.subscriptions}
${payload.bundleId}${listBeneficiaries.beneficiaries}`, payload.beneficiary, config);
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}
async function saveEditedBeneficiary({ token, userInfo }, payload) {
  try {
    const config = axiosConfiguration(token);
    config.headers['Content-Type'] = BENEFICIARY_V1;
    const response = await axios.put(`${listBeneficiaries.main}${payload.walletId}${listBeneficiaries.subscriptions}
${payload.bundleId}${listBeneficiaries.beneficiaries}${payload.beneficiaryId}`, payload.beneficiary, config);
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function removeBeneficiary({ token, userInfo }, payload) {
  try {
    const config = axiosConfiguration(token);
    config.headers['Content-Type'] = BENEFICIARY_V1;
    const response = await axios.delete(`${listBeneficiaries.main}${payload.walletId}${listBeneficiaries.subscriptions}
${payload.bundleId}${listBeneficiaries.beneficiaries}${payload.beneficiaryId}`, config);
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getInvoiceDetails(payload, { token, userInfo }) {
  try {
    const response = await axios.get(`${invoice + payload}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function triggerAllInvoicesPayment(payload, { token, userInfo }) {
  try {
    const response = await axios.post(`${userDetails + payload + payAllInvoices}`, {}, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getCatalog({ token, userInfo }) {
  try {
    const response = await axios.get(catalog, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function createSubsPlan({ token, userInfo }, payload) {
  try {
    const { userId } = payload[0];
    const response = await axios.post(`${main + subsPlan}/${userId + subsPlanSuffix}`, payload[0], axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function changeSubsPlan({ token, userInfo }, payload) {
  try {
    const { userId, bundleId } = payload[0];
    const response = await axios.put(`${main + subsPlan}/${userId + main}/${bundleId}`, payload[0],
      axiosConfiguration(token));
    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : error;

    reportErrorToSentry(message, userInfo);
    throw Error(message);
  }
}

async function changeSubsQuota({ token, userInfo }, payload) {
  try {
    const response = await axios.put(
      `${userDetails + payload[1].subscriberId}${subscriptions + payload[1].subscriptionId}${quota}`,
      payload[0],
      axiosConfiguration(token),
    );
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function cancelSubsPlan({ token, userInfo }, payload) {
  try {
    const response = await axios.delete(`${main + subsPlan}/${payload.userId + main}/${payload.bundleId}`,
      axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getListBeneficiaries,
  getUsers,
  getUserDetails,
  getInvoiceDetails,
  triggerAllInvoicesPayment,
  getCatalog,
  createSubsPlan,
  changeSubsPlan,
  changeSubsQuota,
  cancelSubsPlan,
  getLatestAccounts,
  getLatestPayments,
  removeBeneficiary,
  saveBeneficiary,
  saveEditedBeneficiary,
};
