import routesNames from '@/router/routes-names';

const { subscriptions } = routesNames;

const Subscriber = () => import('@/views/subscriptions/users-management/user-details/user-details.vue');

/**
 * @description Get subscriber's detail route
 * @returns {Array<Object>}
 */
export default [{
  component: Subscriber,
  path: `/${subscriptions.main}/${subscriptions.subscriber.main}/:id`,
  name: `${subscriptions.subscriber.main}`,
  props: true,
}];
