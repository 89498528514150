import appConstants from '@/constants/app';
import usersConstants from '@/constants/users';

const { ROLES } = appConstants;
const { PAGINATION, ROLE } = usersConstants.USERS;
const { ORDER } = PAGINATION;

/**
 * @description Get pagination order
 * @param {Boolean} order
 * @returns {String}
 */
const getOrder = (order) => {
  if (order) {
    return ORDER.ASC;
  }

  return ORDER.DESC;
};

/**
 * @description Create a query parameters for requesting a list of users
 * @param {Object} params - parameters for requesting a specific list of users
 * @returns {String} params queries for API
 * @example: page=1&per_page=10&sort=email&order=desc&role=ADMIN
 */
export default function mapUserParametersToQuery(params) {
  const {
    page,
    sort,
    order,
    perPage,
    role,
  } = params;

  const query = [
    `page=${page}`,
    `sort=${sort}`,
    `per_page=${perPage}`,
    `order=${getOrder(order)}`,
  ];

  if (role !== ROLE.ALL) {
    const queryRole = ROLES[role];
    query.push(`role=${queryRole}`);
  }

  return query.join('&');
}
