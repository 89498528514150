export default function initialState() {
  return {
    id: null,
    dialogType: null,
    text: '',
    title: '',
    icon: null,
    show: false,
    canceled: false,
    confirmed: false,
    countdown: {
      show: false,
      /**
       * Countdown start time on miliseconds
       * - Dev: 300000 (300 seconds, 5 minutes)
       * - Production & Staging: 300000 (300 seconds, 5 minutes)
       */
      startTime: parseInt(process.env.VUE_APP_COUNTDOWN_START_TIME_MS, 10),
    },
    buttons: {
      cancelation: '',
      confirmation: '',
    },
    confirmCallback: null,
    cancelCallback: null,
    maxWidth: null,
  };
}
