// Routes names for Exchange product

export default {
  main: 'exchange',
  dashboard: {
    main: 'dashboard',
  },
  campaigns: {
    main: 'campaigns',
    programId: 'programId',
  },
  programs: {
    main: 'programs',
    active: 'active',
    inactive: 'inactive',
    closed: 'closed',
    import: 'import',
  },
  program: {
    main: 'program',
    create: 'create',
    edit: 'edit',
    options: {
      description: 'description',
      flightTarget: 'flight-target',
      passengerProfiles: 'passenger-profiles',
      settings: 'settings',
      alternativeFlights: 'alternative-flights',
      rewards: 'rewards',
      conditions: 'conditions',
    },
  },
};
