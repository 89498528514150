/**
 * @description Get release date
 * @param {Number} date - Date from server on timestamp. Example: 20190611160542
 * @return {String} Example: 'Tue, 11 Jun 2019 16:05:00 GMT'
 */
export default function getReleaseDate(date) {
  const releaseDate = date.toString();
  const year = releaseDate.slice(0, 4);
  const month = releaseDate.slice(4, 6);
  const day = releaseDate.slice(6, 8);
  const hours = releaseDate.slice(8, 10);
  const minutes = releaseDate.slice(10, 12);

  // Note: the month must be an integer between 0 (January) and 11 (December)
  const monthFormated = (month - 1);
  const dateUTC = new Date(Date.UTC(year, monthFormated, day, hours, minutes));

  return dateUTC.toUTCString();
}
