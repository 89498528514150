import routesNames from '@/router/routes-names';
import store from '@/store';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;
const { subscriptions } = routesNames;

const dashboardRoles = store.getters[`${APP.FEATURES}/getDashboardRoles`];

const Dashboard = () => import(/* webpackChunkName: "async-subs-dashboard" */ '@/views/subscriptions/dashboard/dashboard.vue');
const CumulioDashboard = () => import('@/views/commons/dashboards/dashboard-cumulio.vue');

/**
 * @description Get subscriptions dashboard route
 * @returns {Array<Object>}
 */
export default [{
  component: Dashboard,
  path: `/${subscriptions.main}/${subscriptions.dashboard.main}/:product`,
  name: `${subscriptions.main}.${subscriptions.dashboard.main}.product`,
  meta: {
    authorize: dashboardRoles,
  },
},
{
  component: Dashboard,
  path: `/${subscriptions.main}/${subscriptions.dashboard.main}`,
  name: `${subscriptions.main}.${subscriptions.dashboard.main}`,
  meta: {
    authorize: dashboardRoles,
  },
},
{
  component: CumulioDashboard,
  path: `/${subscriptions.main}/${subscriptions.dashboard.cumulio}`,
  name: `${subscriptions.main}.${subscriptions.dashboard.cumulio}`,
  meta: {
    authorize: dashboardRoles,
  },
},
];
