export default {
  LOGS: {
    PAGINATION: {
      STORE_BY: {
        DATE: 'date',
      },
      ORDER: {
        ASC: 'asc',
        DESC: 'desc',
      },
    },
  },
};
