import isNil from 'lodash.isnil';
import * as Sentry from '@sentry/browser';
import keycloakClient from '@/middleware/auth/keycloakClient';
import saveRedictPath from '@/middleware/auth/utils/saveRedictPath';
import saveExpireAtSession from '@/middleware/auth/utils/saveExpireAtSession';
import removeExpireAtSession from '@/middleware/auth/utils/removeExpireAtSession';
import removeOldSessionItems from '@/middleware/auth/utils/removeOldSessionItems';
import hasActiveSession from './utils/hasActiveSession';

let instance = null;

/**
 * @description Service for Keycloak
*/
class Authenticator {
  constructor(client) {
    if (isNil(client)) {
      throw new Error('Authenticator class can not be called directly');
    }

    this.client = client;
  }

  /**
   * @description Create an instance of keycloak client
   * @returns {Object}
   */
  static build() {
    return keycloakClient().then((client) => new Authenticator(client));
  }

  /**
   * @description Authenticate the user
   * @param {Boolean} activeSession
  */
  async authenticateUser(activeSession) {
    let initParam = {};
    if (activeSession) {
      initParam = { onLoad: 'check-sso', checkLoginIframe: false };
    } else {
      initParam = { onLoad: 'login-required', redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI };
    }
    await this.client.init(initParam).then((auth) => {
      if (!auth) {
        removeExpireAtSession();
        window.location.reload();
      }

      setInterval(() => {
        this.client.updateToken(70).catch((error) => {
          throw error;
        });
      }, 6000);
    }).catch((error) => {
      throw error;
    });
  }

  /**
   * @description Login the user on Keycloak
   * @param {String} path
   * @see {@link https://auth0.github.io/auth0-spa-js/classes/auth0client.html#loginwithredirect}
  */
  async login(path) {
    await saveRedictPath(path);
    await this.authenticateUser(false);
  }

  /**
   * @description Parse the hash after the redirect
   * @see {@link https://auth0.github.io/auth0-spa-js/classes/auth0client.html#handleredirectcallback}
   * @returns {Promise}
   */
  async handleRedirectCallback() {
    try {
      await this.authenticateUser(true);
      await saveExpireAtSession();
      await removeOldSessionItems();
    } catch (error) {
      const message = `Keycloak is not able to handle callback: ${JSON.stringify(error)}`;
      Sentry.captureException(new Error(message));
      await this.logout({
        returnTo: process.env.VUE_APP_AUTH_LOGOUT_URI,
      });
    }
  }

  /**
   * @description Get token silently
   * @see {@link https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently}
   * @returns {Promise}
   */
  async getTokenSilently() {
    try {
      return await this.client.idToken;
    } catch (error) {
      const message = `Keycloak is not able to request the access token: ${JSON.stringify(error)}`;
      Sentry.captureException(new Error(message));
      throw new Error('app.error.auth.main');
    }
  }

  /**
   * @description Logout the user from Keycloak
   * @returns {Promise}
   */
  async logout() {
    await removeExpireAtSession();

    this.client.logout({
      redirectUri: process.env.VUE_APP_AUTH_LOGOUT_URI,
    });
  }
}

/**
 * @description Create an instance of Authenticator
 * @returns {Authenticator}
 */
async function createInstance() {
  instance = await Authenticator.build();

  // If the user has logged in before, he should be reauthenticated,
  // Hence, keycloak instance is populated again.
  if (hasActiveSession()) {
    await instance.authenticateUser(true);
  }
  return instance;
}

/**
 * @description Returns the instance of Authenticator
 * @returns {Authenticator}
 */
export default isNil(instance) ? createInstance() : instance;
