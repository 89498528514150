import routesNames from '@/router/routes-names';

const { settings } = routesNames;

function Routes() {
  return import(/* webpackChunkName: "async-settings-routes" */ '@/views/settings/routes/routes.vue');
}

function List() {
  return import(/* webpackChunkName: "async-settings-route-list" */ '@/views/settings/routes/list/list.vue');
}

function Create() {
  return import(/* webpackChunkName: "async-settings-route-create" */ '@/views/settings/routes/create/create.vue');
}

export default {
  path: `${settings.routes.main}`,
  component: Routes,
  children: [
    {
      path: '',
      component: List,
      name: `${settings.main}.${settings.routes.main}.${settings.routes.list}`,
    },
    {
      path: `${settings.routes.create}`,
      component: Create,
      name: `${settings.main}.${settings.routes.main}.${settings.routes.create}`,
    },
  ],
};
