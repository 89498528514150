import axios from 'axios';
import endpoints from '@/middleware/api/utils/endpoints';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import mapGiftVoucherParametersToQuery from '@/middleware/api/utils/mapGiftVoucherParametersToQuery';

const { vouchers } = endpoints.giftVoucher;
const {
  main,
  invoice,
  issue,
  copy,
  emailIssue,
  emailInvoice,
} = vouchers;

/**
 * @description Get gift vouchers
 * @param {Object} params - parameters for requesting a specific list of giftvouchers
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#seatresale_campaigns_get}
 */
async function getGiftVoucherRequests(payload) {
  const { params, token, userInfo } = payload;

  try {
    const query = mapGiftVoucherParametersToQuery(params);
    const response = await axios.get(`${main}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function copyGiftVoucherRequests(payload) {
  const { params, token, userInfo } = payload;

  try {
    const query = mapGiftVoucherParametersToQuery(params);
    const response = await axios.get(`${copy}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getInvoiceMail(payload) {
  const { params, token, userInfo } = payload;
  try {
    const query = `giftVoucherId=${params.giftVoucherId}`;
    const response = await axios.get(`${invoice}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function getIssueMail(payload) {
  const { params, token, userInfo } = payload;
  try {
    const query = `giftVoucherId=${params.giftVoucherId}`;
    const response = await axios.get(`${issue}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function resendEmailIssue(payload) {
  const { params, token, userInfo } = payload;
  try {
    const query = `giftVoucherId=${params.giftVoucherId}`;
    const response = await axios.post(`${emailIssue}?${query}`, {}, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

async function resendEmailInvoice(payload) {
  const { params, token, userInfo } = payload;
  try {
    const query = `giftVoucherId=${params.giftVoucherId}`;
    const response = await axios.post(`${emailInvoice}?${query}`, {}, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getGiftVoucherRequests,
  getInvoiceMail,
  getIssueMail,
  resendEmailIssue,
  resendEmailInvoice,
  copyGiftVoucherRequests,
};
