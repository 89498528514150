import routesNames from '@/router/routes-names';

const { search } = routesNames;

const Search = () => import(/* webpackChunkName: "async-search-by-pnr" */ '@/views/search-by-pnr/search-by-pnr.vue');

/**
 * @description Get search
 * @returns {Array<Object>}
 */
export default [{
  component: Search,
  path: `/${search.main}/${search.pnr.main}/:pnr`,
  name: `${search.main}.${search.pnr.main}`,
}];
