import api from '@/middleware/api';
import appConstants from '@/constants/app';
import getApiConfiguration from '@/store/utils/getApiConfiguration';
import transformData from '@/store/app/alerts/utils/transformData';
import types from './utils/types';

const { STATUS } = appConstants;
const { OK } = STATUS;

const {
  SAVE_PROGRAMS,
} = types;

/**
 * @description Request a list of programs that will be expired on X days
 * @param {Function} commit from Vuex
 * @param {Object} rootGetters from Vuex
 * @param {number} days - The days to be expired
 * @returns {Promise}
 */
async function requestPrograms({ commit, rootGetters }, days) {
  try {
    const apiConfiguration = await getApiConfiguration(rootGetters);
    const { data } = await api.alerts.getPrograms({ days, ...apiConfiguration });

    commit(SAVE_PROGRAMS, transformData(data));

    return OK;
  } catch (error) {
    throw new Error('app.error.configuration.request');
  }
}

export default {
  requestPrograms,
};
