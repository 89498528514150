import app from './app';
import search from './search';
import upsells from './upsells';
import profile from './profile';
import exchange from './exchange';
import settings from './settings';
import chatbot from './chatbot';
import subscriptions from './subscriptions';
import couponpass from './couponpass';
import giftVoucher from './gift-voucher';
import smartAlerts from './smart-alerts';
import reports from './reports';

/**
 * @description Routes names
 * @returns {Object}
 */
export default {
  ...app,
  exchange,
  upsells,
  search,
  profile,
  settings,
  chatbot,
  subscriptions,
  couponpass,
  giftVoucher,
  smartAlerts,
  reports,
};
