import routesNames from '@/router/routes-names';

const { smartAlerts } = routesNames;

function SmartAlerts() {
  return import(/* webpackChunkName: "async-alerts" */ '@/views/alerts/alerts.vue');
}

function Programs() {
  return import(/* webpackChunkName: "async-alerts-programs" */ '@/views/alerts/programs/programs.vue');
}

export default [{
  path: `/${smartAlerts.main}`,
  component: SmartAlerts,
  redirect: `${smartAlerts.main}/${smartAlerts.programs}`,
  children: [
    // Programs
    {
      path: `${smartAlerts.programs}`,
      component: Programs,
      name: `${smartAlerts.main}.${smartAlerts.programs}`,
    },
  ],
}];
