import Dashboard from '@/router/routes/subscriptions/dashboard';
import UserManagement from '@/router/routes/subscriptions/users-management';
import Subscriber from '@/router/routes/subscriptions/subscriber';
import Promos from '@/router/routes/subscriptions/promos';

export default [
  ...Dashboard,
  ...UserManagement,
  ...Subscriber,
  ...Promos,
];
