import axios from 'axios';
import isNil from 'lodash.isnil';
import URLSearchParams from '@ungap/url-search-params';
import endpoints from '@/middleware/api/utils/endpoints';
import isValidBidProduct from '@/middleware/api/utils/isValidBidProduct';
import mapBidParametersToQuery from '@/middleware/api/utils/mapBidParametersToQuery';
import mapSearchFilterParametersToQuery from '@/middleware/api/utils/mapSearchFilterParametersToQuery';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';

const { bids } = endpoints.upsells;
const { main, refund } = bids;

/**
 * @description Get bids
 * @param {Object} params - parameters for requesting a specific list of bids
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#upsells_bids_get}
 */
async function getBids(payload) {
  const { params, token, userInfo } = payload;

  try {
    const queryStrings = [
      mapBidParametersToQuery(params),
      mapSearchFilterParametersToQuery(params),
    ];
    const query = queryStrings.join('&');
    const response = await axios.get(`${main}?${query}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Get bid
 * @param {String} pnr - Passenger Name Record
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#upsells_bids__pnr__get}
 */
async function getBid(payload) {
  const { pnr, token, userInfo } = payload;

  if (isNil(pnr)) {
    return Promise.reject(new Error('app.error.pnr'));
  }

  try {
    const response = await axios.get(`${main}/${pnr}`, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * @description Bid refund
 * @param {String} product - Product (xs, upg)
 * @param {String} pnr - Passenger Name Record
 * @param {String} segmentKey - Segment key
 * @param {String} token - access token
 * @param {Object} userInfo - The user who request the action
 * @returns {Promise}
 * @see {@link https://documentation.caravelo.net/columbus-api/#upsells_bids_refund__type___pnr__post}
 */
async function bidRefund(payload) {
  const {
    product,
    pnr,
    segmentKey,
    token,
    userInfo,
  } = payload;

  const params = new URLSearchParams();

  if (isNil(product) || !isValidBidProduct(product)) {
    return Promise.reject(new Error('app.error.bids.product'));
  }

  if (isNil(pnr)) {
    return Promise.reject(new Error('app.error.pnr'));
  }

  if (isNil(segmentKey)) {
    return Promise.reject(new Error('app.error.bids.segmentKey'));
  }

  params.append('segmentKey', segmentKey);

  try {
    const response = await axios.post(`${main}/${refund}/${product}/${pnr}`, params, axiosConfiguration(token));
    return response;
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

export default {
  getBid,
  getBids,
  bidRefund,
};
