import { serialize } from 'tinyduration';
import { addDays } from 'date-fns';

export default function mapPromoCreatePayload(payload) {
  const newPayload = {
    promo: {
      name: payload.campaignName,
      promoType: payload.campaignAudience,
      promoPlans: [],
      startDate: new Date(payload.campaignRange[0]).toISOString(),
      endDate: new Date(payload.campaignRange[1]).toISOString(),
      isActive: typeof payload.isActive !== 'undefined'
        ? payload.isActive
        : true,
    },
    coupons: {
      couponType: payload.codeType,
      quantity: parseInt(payload.codesAmount, 10),
      code: payload.codeName,
      // substract 1 day from codesDuration because we count the starting day as well
      expirationDate: !payload.codesDuration
        ? new Date(payload.campaignRange[1]).toISOString()
        : addDays(new Date(payload.campaignRange[0]), payload.codesDuration - 1).toISOString(),
    },
  };

  payload.plans.forEach((plan) => {
    newPayload.promo.promoPlans.push({
      i18n: plan.i18n,
      promoName: null,
      targetName: plan.name,
      duration: serialize({ [plan.period]: plan.periodQty }),
      prices: plan.promoPrices,
      originalPrices: plan.originalPrices,
    });
  });

  return newPayload;
}
