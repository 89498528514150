export default {
  USERS: {
    ROLE: {
      ALL: 'ALL',
      SUPER: 'SUPER',
      ADMIN: 'ADMIN',
      CREATOR: 'CREATOR',
      VIEWER: 'VIEWER',
      SUPERVISOR: 'SUPERVISOR',
    },
    EVENTS: {
      VIEW_USER: 'view-user',
      CREATE_USER: 'create-user',
      UPDATE_USER: 'update-user',
      DELETE_USER: 'delete-user',
      UPDATE_PROFILE: 'update-profile',
      RANDOM_PASSWORD: 'random-password',
      UPDATE_PROFILE_PASSWORD: 'update-profile-password',
    },
    PASSWORD: {
      MIN_LENGTH: 8,
      MAX_LENGTH: 50,
    },
    VALIDATION: {
      DIGITS: 'digits',
      MIN_LENGTH: 'min',
      MAX_LENGTH: 'max',
      SPACES: 'spaces',
      LOWERCASE: 'lowercase',
      UPPERCASE: 'uppercase',
    },
    PAGINATION: {
      STORE_BY: {
        NAME: 'name',
      },
      ORDER: {
        ASC: 'asc',
        DESC: 'desc',
      },
    },
  },
};
