<template>
  <v-overlay
    :value="show"
    z-index="10"
  >
    <v-progress-circular
      :size="size"
      :color="color"
      :width="width"
      indeterminate
    />
    <p class="text-center mt-4">
      {{ message }}
    </p>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex';
import storeConstants from '@/constants/store';

const { APP } = storeConstants;

export default {
  name: 'Spinner',

  data() {
    return {
      size: 100,
      width: 5,
      color: 'white',
    };
  },

  computed: {
    ...mapState([APP.SPINNER]),

    show() {
      return this.appSpinner.show;
    },

    message() {
      return this.appSpinner.message;
    },
  },
};
</script>
