<template>
  <v-snackbar
    v-model="show"
    :color="color()"
    :bottom="bottom"
    :timeout="timeout"
  >
    <v-icon
      small
      left
      color="white"
    >
      {{ icon() }}
    </v-icon>

    <span v-html="message" />

    <v-btn
      v-if="isAlert()"
      text
      @click="handleViewAlerts()"
    >
      View
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';

const { STATUS } = appConstants;
const { APP } = storeConstants;
const { OK, ALERTS } = STATUS;
const { smartAlerts } = routesNames;

export default {
  name: 'Snackbar',
  data() {
    return {
      bottom: true,
      timeout: 10000,
    };
  },

  computed: {
    ...mapState([APP.SNACKBAR]),

    show: {
      get() {
        return this.appSnackbar.show;
      },

      set() {
        this.hideSnackbar();
      },
    },

    message() {
      return this.appSnackbar.message;
    },

    status() {
      return this.appSnackbar.status;
    },
  },

  methods: {
    ...mapActions({
      hideSnackbar: `${APP.SNACKBAR}/hide`,
    }),

    isSuccess() {
      return (this.status === OK);
    },

    isAlert() {
      return (this.status === ALERTS);
    },

    icon() {
      if (this.isSuccess()) {
        return this.$t('app.icons.check');
      }

      if (this.isAlert()) {
        return this.$t('app.icons.bell');
      }

      return this.$t('app.icons.error');
    },

    color() {
      if (this.isSuccess()) {
        return 'green';
      }

      if (this.isAlert()) {
        return 'orange';
      }

      return 'red';
    },

    /**
     * Handle view alerts
     */
    handleViewAlerts() {
      // First, redirect to the alerts page
      this.$router.push({
        path: `/${smartAlerts.main}`,
      });

      // Then hide the snackbar
      this.show = false;
    },

  },
};
</script>
