import routesNames from '@/router/routes-names';

const { upsells } = routesNames;
const { programs } = upsells;

function Programs() {
  return import(/* webpackChunkName: "async-up-programs" */ '@/views/upsells/programs/programs.vue');
}

function ProgramsActive() {
  return import(/* webpackChunkName: "async-up-programs-active" */ '@/views/upsells/programs/table/active/active.vue');
}

function ProgramsInactive() {
  return import(/* webpackChunkName: "async-up-programs-inactive" */ '@/views/upsells/programs/table/inactive/inactive.vue');
}

/**
 * @description Get upsells programs routes
 * @returns {Array<Object>}
 */
export default [
  {
    component: Programs,
    path: `/${upsells.main}/${programs.main}`,
    redirect: `${upsells.main}/${programs.main}/${programs.active}`,
    children: [
      {
        component: ProgramsActive,
        path: `${programs.active}`,
        name: `${upsells.main}.${programs.main}.${programs.active}`,
      },
      {
        component: ProgramsInactive,
        path: `${programs.inactive}`,
        name: `${upsells.main}.${programs.main}.${programs.inactive}`,
      },
    ],
  },
];
